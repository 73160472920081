import React,{useState,useEffect} from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import {Navbar} from 'react-bootstrap';
import NavDropdown from 'react-bootstrap/NavDropdown';
import './components.css'
import AcvlLogo from '../images/Ukmover.png'
import { RiArrowRightSLine } from "react-icons/ri";
import strip from '../images/strip2.png';
import ACVllogofooter from '../images/ACVl-logo-footer.png';
import { BiSolidPhoneCall } from "react-icons/bi";
import { MdEmail } from "react-icons/md";
//001E80

const Header = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [scroll, setScroll] = useState(false);


  const handleDropdownShow = () => {
    setShowDropdown(true);
  };

  const handleDropdownHide = () => {
    setTimeout(() => {
      setShowDropdown(false);
    }, 500);
  };


  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 10);
    });
  });


  const [isSidenavOpen, setIsSidenavOpen] = useState(false);

  const openNav = () => {
    setIsSidenavOpen(true);
  };

  const closeNav = () => {
    setIsSidenavOpen(false);
  };


  return (
    <>
          <div>
      <header className={`headerMain ${scroll ? "sticky" : ""}`}>
        <Navbar className='servceNavbar'  expand="lg" style={{backgroundColor:'#FFFFFF',backgroundColor: 'transparent',
       backgroundImage: 'linear-gradient(42deg, #f8f8f8 23%, rgb(31, 35, 112) 0%)', height:'110px'}}>
        <Container>
        <a href="/">
            <img src={AcvlLogo} alt='ACVLlogo' style={{width:'100px'}} className='logoinResponse' />
        </a>
        <Navbar.Toggle  trols="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
<>
        <div style={{ marginLeft: '20%', color: '#fff' }}>
          <div className='d-flex align-items-center'>
            <a href="tel:+1-833-372-9071"  style={{textDecoration:'none',color:'#000'}}><div className='hearts' style={{backgroundColor:'#F00921', borderRadius: '50%', border: '0px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center',cursor:'pointer',marginLeft:'10px', width: '50px', height: '50px', alignItems: 'center'}}>
                <BiSolidPhoneCall style={{color:'#ffff'}} size={30} />
              </div></a>
              <a href="tel:+1-833-372-9071"  style={{textDecoration:'none',color:'#ffff'}}> <div style={{ marginLeft: '30px'}}>
                <p className='mb-1' style={{fontFamily:'Lato, sans-serif', fontWeight: '700'}}>Call us toll-free</p>
                <h3 style={{ fontSize: 'x-large',fontWeight: '700',fontFamily:'Lato, sans-serif',fontSize: '18px'}}>1-833-372-9071</h3>
              </div></a>
            </div>
        </div>
        <div style={{ marginLeft: '10%', color: '#fff' }}>
          <div className='d-flex align-items-center'>
            <a href="tel:+1-833-372-9071"  style={{textDecoration:'none',color:'#000'}}><div className='hearts' style={{backgroundColor:'#F00921', borderRadius: '50%', border: '0px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center',cursor:'pointer',marginLeft:'10px', width: '50px', height: '50px', alignItems: 'center'}}>
                <MdEmail style={{color:'#ffff'}} size={30} />
              </div></a>
              <a href="tel:+1-833-372-9071"  style={{textDecoration:'none',color:'#ffff'}}> <div style={{ marginLeft: '30px'}}>
                <p className='mb-1' style={{fontFamily:'Lato, sans-serif', fontWeight: '700'}}>E-mail us</p>
                <h3 style={{ fontSize: 'x-large',fontWeight: '700',fontFamily:'Lato, sans-serif',fontSize: '18px'}}>support@ukmover.com</h3>
              </div></a>
            </div>
        </div>
        </>

        {/* <Nav className="HeaderMenu2">
          <div className='servceNavbar'>
            <NavDropdown
            style={{ fontFamily: 'Lato, sans-serif', fontWeight: '600', margin: '1px 0px', color: '#000' }}
            title={<span style={{ color: '#000' }}>OUR SERVICE</span>} className='topHoverHead'>
            <a href="/local-movers" style={{ fontFamily: 'Lato, sans-serif', fontWeight: '500', textDecoration: 'none', color: '#000' }} className='dropDownSer dropdown-item'>
              <RiArrowRightSLine style={{ marginLeft: '0px' }} /> Local Movers
            </a>
            <a href="/long-distance-movers" style={{ fontFamily: 'Lato, sans-serif', fontWeight: '500', textDecoration: 'none', color: '#000' }} className='dropDownSer dropdown-item'>
              <RiArrowRightSLine style={{ marginLeft: '0px' }} /> Long Distance Movers
            </a>
            <a href="/storage-services" style={{ fontFamily: 'Lato, sans-serif', fontWeight: '500', textDecoration: 'none', color: '#000' }} className='dropDownSer dropdown-item'>
              <RiArrowRightSLine style={{ marginLeft: '0px' }} /> Storage Services
            </a>
            <NavDropdown.Divider />
            <a href="/junk-removal" style={{ fontFamily: 'Lato, sans-serif', fontWeight: '500', textDecoration: 'none', color: '#000' }} className='dropDownSer dropdown-item'>
              <RiArrowRightSLine style={{ marginLeft: '0px' }} /> Junk Removal
            </a>
            <a href="/moving-supplier" style={{ fontFamily: 'Lato, sans-serif', fontWeight: '500', textDecoration: 'none', color: '#000' }} className='dropDownSer dropdown-item'>
              <RiArrowRightSLine style={{ marginLeft: '0px' }} /> Moving Supplies
            </a>
            <a href="/packaging-services" style={{ fontFamily: 'Lato, sans-serif', fontWeight: '500', textDecoration: 'none', color: '#000' }} className='dropDownSer dropdown-item'>
              <RiArrowRightSLine style={{ marginLeft: '0px' }} /> Packing services
            </a>
            </NavDropdown>
            <a href="/Contact" className='topHoverHead' style={{padding:'8px 0px',fontWeight:'bold', margin: '10px 10px', textDecoration:'none',color:'#000'}}>CONTACT US</a>
          </div>
        </Nav> */}
      
          {/* <div className='getQuoteBtn' style={{ position: 'absolute',top: 0, right: 0 }}>
            <div style={{position: 'relative',textAlign: 'center'}}>
            <img src={strip} alt='strip'style={{width:'100%'}} />
            <div style={{color:'#ffff',  position: 'absolute',top: '10%',left: '15%'}}>
            <div className='d-flex align-items-center'>
            <a href="tel:+1-833-372-9071"  style={{textDecoration:'none',color:'#000'}}><div className='heart' style={{backgroundColor:'#FC4243', borderRadius: '50%', border: '0px solid #000', width: '40px', height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center',cursor:'pointer',marginLeft:'10px' }}>
                <PiPhoneCallFill style={{color:'#ffff'}} size={30} />
              </div></a>
              <a href="tel:+1-833-372-9071"  style={{textDecoration:'none',color:'#ffff'}}> <div style={{ marginLeft: '30px'}}>
                <p className='mb-1' style={{fontFamily:'Lato, sans-serif'}}>Call Us Toll Free</p>
                <h1 style={{ fontSize: 'x-large',fontWeight: '700',fontFamily:'Lato, sans-serif'}}>1-833-372-9071</h1>
              </div></a>
            </div>
            </div>
            </div>
          </div> */}
        </Navbar.Collapse>
      </Container>
    </Navbar>
    {/* Responsive */}
    <div className='servceNavbarResponsive'>
    <header>
        <nav>
          <div id="navbar">
            <div id="logo" className="reverse">
               <div className="mobile-btn" style={{ fontSize: 45, cursor: 'pointer', fontWeight: 'bold'}} onClick={openNav}>
                &#9776;
              </div>
              <a href="/" style={{textAlign: 'end'}}>
                <img src={AcvlLogo} alt='ACVLlogo' style={{width:'200px',marginTop:'10px'}} />
            </a>
            </div>
          </div>
        </nav>
        <div id="mySidenav" className="sidenav" style={{ width: isSidenavOpen ? '250px' : '0' }}>
        <a href="/" style={{ position: 'absolute',top: '15px',left: '5px'}}>
                <img src={ACVllogofooter} alt='ACVLlogo' style={{width:'100px'}} />
            </a>
          <a style={{ cursor: 'pointer' }} className="closebtn" onClick={closeNav}>&times;</a><hr style={{color:'#ffff'}}/>
          <a href="#" style={{color:'#ffff', fontSize: '25px'}}>OUR SERVICE</a>
          <a href="/local-movers">Local Movers</a>
          <a href="/long-distance-movers">Long Distance Movers</a>
          <a href="/junk-removal">Junk Removal</a>
          <a href="/packaging-services">Packing Services</a>
          <a href="/moving-supplier">Moving Supplies</a>
          <a href="/storage-services">Storage Services</a><hr style={{color:'#ffff'}}/>
          {/* <a href="/blogs" style={{color:'#ffff', fontSize: '25px', paddingTop:'30px'}}>OUR BLOG</a> */}
          <a href="/Contact" style={{color:'#ffff', fontSize: '25px', paddingTop:'5px'}}>CONTACT US</a>
        </div>
      </header>
    </div>
    <div>
    <Navbar expand="lg" className="bg-body-tertiary mx-5 header2set" style={{ position: 'absolute',width: '95%',top: '82px', borderRadius:'10px'}}>
      <Container>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
          <a href="/" className='topHoverHead' style={{padding:'8px 0px',fontWeight:'bold', margin: '10px 15px', textDecoration:'none',color:'#000'}}>HOME</a>

          <NavDropdown
            style={{ fontFamily: 'Lato, sans-serif', fontWeight: '600', margin: '10px 0px', color: '#000' }}
            title={<span style={{ color: '#000' }}>OUR SERVICE</span>} className='topHoverHead'>
            <a href="/local-movers" style={{ fontFamily: 'Lato, sans-serif', fontWeight: '500', textDecoration: 'none', color: '#000' }} className='dropDownSer dropdown-item'>
              <RiArrowRightSLine style={{ marginLeft: '0px' }} /> Local Movers
            </a>
            <a href="/long-distance-movers" style={{ fontFamily: 'Lato, sans-serif', fontWeight: '500', textDecoration: 'none', color: '#000' }} className='dropDownSer dropdown-item'>
              <RiArrowRightSLine style={{ marginLeft: '0px' }} /> Long Distance Movers
            </a>
            <a href="/storage-services" style={{ fontFamily: 'Lato, sans-serif', fontWeight: '500', textDecoration: 'none', color: '#000' }} className='dropDownSer dropdown-item'>
              <RiArrowRightSLine style={{ marginLeft: '0px' }} /> Storage Services
            </a>
            <NavDropdown.Divider />
            <a href="/junk-removal" style={{ fontFamily: 'Lato, sans-serif', fontWeight: '500', textDecoration: 'none', color: '#000' }} className='dropDownSer dropdown-item'>
              <RiArrowRightSLine style={{ marginLeft: '0px' }} /> Junk Removal
            </a>
            <a href="/moving-supplier" style={{ fontFamily: 'Lato, sans-serif', fontWeight: '500', textDecoration: 'none', color: '#000' }} className='dropDownSer dropdown-item'>
              <RiArrowRightSLine style={{ marginLeft: '0px' }} /> Moving Supplies
            </a>
            <a href="/packaging-services" style={{ fontFamily: 'Lato, sans-serif', fontWeight: '500', textDecoration: 'none', color: '#000' }} className='dropDownSer dropdown-item'>
              <RiArrowRightSLine style={{ marginLeft: '0px' }} /> Packing services
            </a>
            </NavDropdown>

          <a href="/Contact" className='topHoverHead' style={{padding:'8px 0px',fontWeight:'bold', margin: '10px 15px', textDecoration:'none',color:'#000'}}>OUR BLOG</a>
          <a href="/Contact" className='topHoverHead' style={{padding:'8px 0px',fontWeight:'bold', margin: '10px 15px', textDecoration:'none',color:'#000'}}>ABOUT US</a>
          <a href="/Contact" className='topHoverHead' style={{padding:'8px 0px',fontWeight:'bold', margin: '10px 15px', textDecoration:'none',color:'#000'}}>CONTACT US</a>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </div>
    </header>
 

    </div>
    </>

  )
}

export default Header   


// <div>
    //   <header className={`headerMain ${scroll ? "sticky" : ""}`}>
    //     <Navbar className='servceNavbar'  expand="lg" style={{backgroundColor:'#FFFFFF'}}>
    //     <Container>
    //     <a href="/">
    //         <img src={AcvlLogo} alt='ACVLlogo' style={{width:'100px'}} />
    //     </a>
    //     <Navbar.Toggle  trols="basic-navbar-nav" />
    //     <Navbar.Collapse id="basic-navbar-nav">
          
    //     <Nav className="HeaderMenu2">
    //       {/* Desktop View */}
    //       <div className='servceNavbar'>
    //         <NavDropdown
    //         style={{ fontFamily: 'Lato, sans-serif', fontWeight: '600', margin: '1px 0px', color: '#000' }}
    //         title={<span style={{ color: '#000' }}>OUR SERVICE</span>} className='topHoverHead'>
    //         <a href="/local-movers" style={{ fontFamily: 'Lato, sans-serif', fontWeight: '500', textDecoration: 'none', color: '#000' }} className='dropDownSer dropdown-item'>
    //           <RiArrowRightSLine style={{ marginLeft: '0px' }} /> Local Movers
    //         </a>
    //         <a href="/long-distance-movers" style={{ fontFamily: 'Lato, sans-serif', fontWeight: '500', textDecoration: 'none', color: '#000' }} className='dropDownSer dropdown-item'>
    //           <RiArrowRightSLine style={{ marginLeft: '0px' }} /> Long Distance Movers
    //         </a>
    //         <a href="/storage-services" style={{ fontFamily: 'Lato, sans-serif', fontWeight: '500', textDecoration: 'none', color: '#000' }} className='dropDownSer dropdown-item'>
    //           <RiArrowRightSLine style={{ marginLeft: '0px' }} /> Storage Services
    //         </a>
    //         <NavDropdown.Divider />
    //         <a href="/junk-removal" style={{ fontFamily: 'Lato, sans-serif', fontWeight: '500', textDecoration: 'none', color: '#000' }} className='dropDownSer dropdown-item'>
    //           <RiArrowRightSLine style={{ marginLeft: '0px' }} /> Junk Removal
    //         </a>
    //         <a href="/moving-supplier" style={{ fontFamily: 'Lato, sans-serif', fontWeight: '500', textDecoration: 'none', color: '#000' }} className='dropDownSer dropdown-item'>
    //           <RiArrowRightSLine style={{ marginLeft: '0px' }} /> Moving Supplies
    //         </a>
    //         <a href="/packaging-services" style={{ fontFamily: 'Lato, sans-serif', fontWeight: '500', textDecoration: 'none', color: '#000' }} className='dropDownSer dropdown-item'>
    //           <RiArrowRightSLine style={{ marginLeft: '0px' }} /> Packing services
    //         </a>
    //         </NavDropdown>
    //         {/* Others */}
    //         {/* <a href="#Aboutus" className='topHoverHead' style={{padding:'8px 0px',fontFamily:'Lato, sans-serif', fontWeight:'600', margin: '0px 15px', textDecoration:'none',color:'#000'}}>ABOUT US</a> */}
    //         <a href="/Contact" className='topHoverHead' style={{padding:'8px 0px',fontWeight:'bold', margin: '10px 10px', textDecoration:'none',color:'#000'}}>CONTACT US</a>
    //         {/* <a href="/blogs" className='topHoverHead' style={{padding:'8px 0px', fontWeight:'bold', margin: '10px 10px', textDecoration:'none',color:'#000'}}>BLOG</a> */}
    //       </div>
    //     </Nav>
      
    //       <div className='getQuoteBtn' style={{ position: 'absolute',top: 0, right: 0 }}>
    //         <div style={{position: 'relative',textAlign: 'center'}}>
    //         <img src={strip} alt='strip'style={{width:'100%'}} />
    //         <div style={{color:'#ffff',  position: 'absolute',top: '10%',left: '15%'}}>
    //         <div className='d-flex align-items-center'>
    //         <a href="tel:+1-833-372-9071"  style={{textDecoration:'none',color:'#000'}}><div className='heart' style={{backgroundColor:'#FC4243', borderRadius: '50%', border: '0px solid #000', width: '40px', height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center',cursor:'pointer',marginLeft:'10px' }}>
    //             <PiPhoneCallFill style={{color:'#ffff'}} size={30} />
    //           </div></a>
    //           <a href="tel:+1-833-372-9071"  style={{textDecoration:'none',color:'#ffff'}}> <div style={{ marginLeft: '30px'}}>
    //             <p className='mb-1' style={{fontFamily:'Lato, sans-serif'}}>Call Us Toll Free</p>
    //             <h1 style={{ fontSize: 'x-large',fontWeight: '700',fontFamily:'Lato, sans-serif'}}>1-833-372-9071</h1>
    //           </div></a>
    //         </div>
    //         </div>
    //         </div>
    //       </div>
    //     </Navbar.Collapse>
    //   </Container>
    // </Navbar>
    // {/* Responsive */}
    // <div className='servceNavbarResponsive'>
    // <header>
    //     <nav>
    //       <div id="navbar">
    //         <div id="logo" className="reverse">
    //            <div className="mobile-btn" style={{ fontSize: 45, cursor: 'pointer', fontWeight: 'bold'}} onClick={openNav}>
    //             &#9776;
    //           </div>
    //           <a href="/" style={{textAlign: 'end'}}>
    //             <img src={AcvlLogo} alt='ACVLlogo' style={{width:'200px',marginTop:'10px'}} />
    //         </a>
    //         </div>
    //       </div>
    //     </nav>
    //     <div id="mySidenav" className="sidenav" style={{ width: isSidenavOpen ? '250px' : '0' }}>
    //     <a href="/" style={{ position: 'absolute',top: '15px',left: '5px'}}>
    //             <img src={ACVllogofooter} alt='ACVLlogo' style={{width:'100px'}} />
    //         </a>
    //       <a style={{ cursor: 'pointer' }} className="closebtn" onClick={closeNav}>&times;</a><hr style={{color:'#ffff'}}/>
    //       <a href="#" style={{color:'#ffff', fontSize: '25px'}}>OUR SERVICE</a>
    //       <a href="/local-movers">Local Movers</a>
    //       <a href="/long-distance-movers">Long Distance Movers</a>
    //       <a href="/junk-removal">Junk Removal</a>
    //       <a href="/packaging-services">Packing Services</a>
    //       <a href="/moving-supplier">Moving Supplies</a>
    //       <a href="/storage-services">Storage Services</a><hr style={{color:'#ffff'}}/>
    //       {/* <a href="/blogs" style={{color:'#ffff', fontSize: '25px', paddingTop:'30px'}}>OUR BLOG</a> */}
    //       <a href="/Contact" style={{color:'#ffff', fontSize: '25px', paddingTop:'5px'}}>CONTACT US</a>
    //     </div>
    //   </header>
    // </div>
    // </header>
    // </div>




























