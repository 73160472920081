import React, { useState, useEffect } from 'react';
import Header from '../components/Header2'
import Paper from '@mui/material/Paper';
import {Row,Col,Button} from 'react-bootstrap';
import { MdKeyboardArrowRight } from "react-icons/md";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import FormImage from '../images/delivery1.jpg';
import {Link, useNavigate} from 'react-router-dom'
import {Fade,Zoom} from 'react-reveal';
import { FaHome } from "react-icons/fa";
import { MdApartment } from "react-icons/md";
import { BsFillBuildingsFill } from "react-icons/bs";
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { FaArrowLeftLong } from "react-icons/fa6";
import StoreIcon from '@mui/icons-material/Store';
import LocationCityIcon from '@mui/icons-material/LocationCity';

const PropertySelect = () => {
  const location = useLocation();
  const navigate = useNavigate(); 
  const [selectedCircle, setSelectedCircle] = useState(null);
  const [predata, setPredata] = useState(null);
  
  useEffect(() => {
    const existingData = location.state && location.state.data;
    // Check if existingData is different from predata before updating the state
    if (existingData !== predata && predata !== null) {
      setPredata(existingData);
    } else if(predata == null) {
      setPredata(existingData);
    
    }
  }, [location.state, predata]); // Add location.state and predata to the dependency array
  
  useEffect(() => {
    const existingData = location.state && location.state.data;
    if(existingData){
      //alert(existingData.backbutton);  
    }
    
      if (predata && predata.property !== null && predata.property !== undefined) {
        handleCircleClick2(predata.property)
       }
    }, 
    [predata]); // Include selectedCircle in the dependency array
  if (!predata || !predata.from || !predata.to || !predata.selectedDate) {
    // Navigate to a different route if from or to is not present
    navigate('/');

     // return null; // You can return null or some fallback content here
  }
  // const existingData = location.state && location.state.data;
  ///////// end locaton//////////////////////////////////////////////////////
  const data = location.state && location.state.data;

  console.log('Data from state:', data);

  const handleNextButtonClick = (selectedCircle2) => {
       if (selectedCircle2) {
      const existingData = location.state && location.state.data;

      if (!existingData || !existingData.from || !existingData.to  || !existingData.selectedDate) {
        // Navigate to a different route if from or to is not present
         navigate('/');
        return;
      } 
      const data = {
        from: existingData.from,
        to: existingData.to,
        selectedDate: existingData.selectedDate,
        selectedFormattedDate: existingData.selectedFormattedDate,
        ...(existingData || {}), // Spread existing data
        property: selectedCircle2,
      };
  
       console.log('NEXT button clicked. Navigating with data:', );

      navigate('/Propertysize', { state: { data: data } });
    } else {
      // Handle the case where no date is selected
      // alert('Please select a Property type.');
      toast.warn('Please select a Property type', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        })
    }
  };
  const handlePreButtonClick = (event) => {
    event.preventDefault(); 
    if (selectedCircle) {
      const existingData = location.state && location.state.data;
      const data = {
        from: existingData.from,
        to: existingData.to,
        selectedDate: existingData.selectedDate,
        selectedFormattedDate: existingData.selectedFormattedDate,
        ...(existingData || {}), 
        property: selectedCircle,
      };
      //alert('If')
      navigate('/CalenderForm', { state: { data: data } });
    } else {
      const existingData = location.state && location.state.data;

     console.log('existingData',existingData);
      const data = {
        from: existingData.from,
        to: existingData.to,
        selectedDate:existingData.selectedDate,
        selectedFormattedDate: existingData.selectedFormattedDate,
        ...(existingData || {}),
      };      
      navigate('/CalenderForm', { state: { data: data } });
    }
  };

  /////////////////////////////
  // alert(selectedCircle);

  const handleCircleClick = (circle) => {
  // alert();
     setSelectedCircle(circle === selectedCircle ? null : circle);
     handleNextButtonClick(circle === selectedCircle ? null : circle);
  };

  const handleCircleClick2 = (circle) => {
    // alert(circle);
       setSelectedCircle(circle === selectedCircle ? null : circle);
    };
  

    const getCircleStyle = (circle) => ({
      width: '150px',
      height: '150px',
      borderRadius: '50%',
      border: '3px solid #0072BC',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      backgroundColor: circle === selectedCircle ? '#0072BC' : 'transparent',
      color: circle === selectedCircle ? '#ffff' : '#000',
      // Media query for smaller screens
      '@media (max-width: 608px)': {
        width: '100px !important',
        height: '100px !important',
        border: '2px solid #FC4243 !important',
      },
    });
    
  const getCircleStyleSmall = (circle) => ({
    width: '100px',
    height: '100px',
    borderRadius: '50%',
    border: '3px solid #FC4243',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    backgroundColor: circle === selectedCircle ? '#FC4243' : 'transparent',
    color: circle === selectedCircle ? '#ffff' : '#000',
  });


  return (
    <div>
       <Header />
       <ToastContainer />
       {/* <div className='container mt-5'>
        <h6 style={{color:'#FC4243'}}>HEADING</h6>
        <h3 style={{fontFamily:'sans-serif'}}>We will reach out to you in just four simple step</h3>
       </div> */}
       
       <div className='container my-3' style={{padding: '0'}}>
        <div className='formBoxsh'>
          <div className='container'>
            <Row>
              <Col xs={5} style={{padding:'0px'}} className='FormImages'>
              <img src={FormImage} alt='Image' style={{width:'100%',height: '460px',borderRadius:'10px 0px 0px 10px'}} className='FormImages'/>
              </Col>
              <Col xs={12} md={7} style={{padding:'0px',position:'relative'}}>
              <div style={{width:'55%',height: '10%',borderRadius:'0px 10px 10px 0px', border:'1px solid #0C2498', marginTop:'10px', backgroundColor:'#0072BC', display: 'flex',justifyContent: 'center',alignItems: 'center',fontWeight: '600'}}>
               <Zoom right> <h5 style={{color:'#ffff',fontFamily:'Lato, sans-serif',margin:'0px',fontWeight: '600'}}>Select the property type</h5></Zoom>
               </div>
               <button class="button-70" onClick={handlePreButtonClick} style={{position: 'absolute',right: '5%',top: '1%'}}><FaArrowLeftLong className='me-2'/>Back</button>

                <div className='container' style={{ justifyContent: 'center', marginTop:'90px' }}>
                <Fade right>
                  <Row style={{flexWrap: 'wrap',rowGap: '10px',columnGap: '2em'}}>
                    <Col xs={3} className='text-center py-3 selectPropBox offset-0 offset-md-1' style={{boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                       <div className='propertyCircle propertyCircle2' style={getCircleStyle('HOUSE')} onClick={() => handleCircleClick('HOUSE')}>
                        <FaHome size={90} className='propertyCircleIcon'/>
                       </div>
                    </div>
                
                    <h5 className='mt-3'>HOUSE</h5>
                    </Col>
                    <Col xs={3} className='text-center py-3 selectPropBox' style={{boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <div className='propertyCircle propertyCircle2' style={getCircleStyle('APARTMENT')} onClick={() => handleCircleClick('APARTMENT')}>
                        <MdApartment size={90} className='propertyCircleIcon' />
                        </div>
                        </div>
                        <h5 className='mt-3'>APARTMENT</h5>
                    </Col>
                    <Col xs={3} className='text-center py-3 selectPropBox' style={{boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <div className='propertyCircle propertyCircle3' style={getCircleStyle('COMMERCIAL')} onClick={() => handleCircleClick('COMMERCIAL')}>
                      {/* <BsFillBuildingsFill size={90} className='propertyCircleIcon'/> */}
                      <StoreIcon style={{fontSize: '100px'}} className='propertyCircleIcon'/>
                    </div>
                    </div>
                    <h5 className='mt-3'>COMMERCIAL</h5>
                    </Col>
                  </Row>
                  </Fade>
                </div>
              </Col>
            </Row>
          </div>
        </div>
 </div>
        {/* Mobile */}
        {/* <div className='CalenderMobileView' elevation={4} style={{borderRadius:'10px'}}>
          <div style={{position: 'relative'}}>
          <div style={{width:'55%',height: '45px',borderRadius:'0px 10px 10px 0px', border:'1px solid #FC4243', marginTop:'10px', backgroundColor:'#FC4243', display: 'flex',justifyContent: 'center',alignItems: 'center',fontWeight: '600'}}>
               <Zoom right> <h5 style={{color:'#ffff',fontFamily:'Lato, sans-serif',margin:'0px',fontWeight: '600'}}>Select the property type</h5></Zoom>
               </div>
               <button class="button-70" onClick={handlePreButtonClick} style={{position: 'absolute',right: '5%',top: '1%'}}><FaArrowLeftLong className='me-2'/>Back</button>
                <div className='container' style={{ display: 'flex', justifyContent: 'center', marginTop:'190px' }}>
                <Fade right>
                  <Row>
                    <Col xs={4}  className='text-center py-3' style={{boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'}}>
                    <div className='getCircleStyleSmall' style={getCircleStyleSmall('HOUSE')} onClick={() => handleCircleClick('HOUSE')}>
                        <FaHome size={70} className='propertyCircleIcon'/>
                    </div>
                    <h6 className='mt-3'>HOUSE</h6>
                    </Col>
                    <Col xs={4} className='text-center py-3' style={{boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'}}>
                    <div className='getCircleStyleSmall' style={getCircleStyleSmall('APARTMENT')} onClick={() => handleCircleClick('APARTMENT')}>
                        <MdApartment size={70} className='propertyCircleIcon' />
                        </div>
                        <h6 className='mt-3'>APARTMENT</h6>
                    </Col>
                    <Col xs={4} className='text-center py-3' style={{boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'}}>
                    <div className='getCircleStyleSmall' style={getCircleStyleSmall('COMMERCIAL')} onClick={() => handleCircleClick('COMMERCIAL')}>
                      <StoreIcon style={{fontSize: '70px'}} className='propertyCircleIcon'/>
                    </div>
                    <h6 className='mt-3'>COMMERCIAL</h6>
                    </Col>
                  </Row>
                  </Fade>
                </div>
          </div>
        </div>
       */}
    </div>
  )
}


export default PropertySelect








// import React, { useState, useEffect } from 'react';
// import Header from '../components/Header'
// import Paper from '@mui/material/Paper';
// import {Row,Col,Button} from 'react-bootstrap';
// import { MdKeyboardArrowRight } from "react-icons/md";
// import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
// import FormImage from '../images/delivery1.jpg';
// import {Link, useNavigate} from 'react-router-dom'
// import {Fade,Zoom} from 'react-reveal';
// import { FaHome } from "react-icons/fa";
// import { MdApartment } from "react-icons/md";
// import { BsFillBuildingsFill } from "react-icons/bs";
// import { useLocation } from 'react-router-dom';
// import { ToastContainer, toast } from 'react-toastify';
// import { FaArrowLeftLong } from "react-icons/fa6";
// import StoreIcon from '@mui/icons-material/Store';
// import LocationCityIcon from '@mui/icons-material/LocationCity';

// const PropertySelect = () => {
//   const location = useLocation();
//   const navigate = useNavigate(); 
//   const [selectedCircle, setSelectedCircle] = useState(null);
//   const [predata, setPredata] = useState(null);
  
//   useEffect(() => {
//     const existingData = location.state && location.state.data;
//     // Check if existingData is different from predata before updating the state
//     if (existingData !== predata && predata !== null) {
//       setPredata(existingData);
//     } else if(predata == null) {
//       setPredata(existingData);
    
//     }
//   }, [location.state, predata]); // Add location.state and predata to the dependency array
  
//   useEffect(() => {
//     const existingData = location.state && location.state.data;
//     if(existingData){
//       //alert(existingData.backbutton);  
//     }
    
//       if (predata && predata.property !== null && predata.property !== undefined) {
//         handleCircleClick2(predata.property)
//        }
//     }, 
//     [predata]); // Include selectedCircle in the dependency array
//   if (!predata || !predata.from || !predata.to || !predata.selectedDate) {
//     // Navigate to a different route if from or to is not present
//     navigate('/');

//      // return null; // You can return null or some fallback content here
//   }
//   // const existingData = location.state && location.state.data;
//   ///////// end locaton//////////////////////////////////////////////////////
//   const data = location.state && location.state.data;

//   console.log('Data from state:', data);

//   const handleNextButtonClick = (selectedCircle2) => {
//        if (selectedCircle2) {
//       const existingData = location.state && location.state.data;

//       if (!existingData || !existingData.from || !existingData.to  || !existingData.selectedDate) {
//         // Navigate to a different route if from or to is not present
//        //   navigate('/');
//         return;
//       } 
//       const data = {
//         from: existingData.from,
//         to: existingData.to,
//         selectedDate: existingData.selectedDate,
//         selectedFormattedDate: existingData.selectedFormattedDate,
//         ...(existingData || {}), // Spread existing data
//         property: selectedCircle2,
//       };
  
//        console.log('NEXT button clicked. Navigating with data:', );

//       navigate('/Propertysize', { state: { data: data } });
//     } else {
//       // Handle the case where no date is selected
//       // alert('Please select a Property type.');
//       toast.warn('Please select a Property type', {
//         position: "top-right",
//         autoClose: 2000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: undefined,
//         theme: "dark",
//         })
//     }
//   };
//   const handlePreButtonClick = (event) => {
//     event.preventDefault(); 
//     if (selectedCircle) {
//       const existingData = location.state && location.state.data;
//       const data = {
//         from: existingData.from,
//         to: existingData.to,
//         selectedDate: existingData.selectedDate,
//         selectedFormattedDate: existingData.selectedFormattedDate,
//         ...(existingData || {}), 
//         property: selectedCircle,
//       };
//       //alert('If')
//       navigate('/CalenderForm', { state: { data: data } });
//     } else {
//       const existingData = location.state && location.state.data;

//      console.log('existingData',existingData);
//       const data = {
//         from: existingData.from,
//         to: existingData.to,
//         selectedDate:existingData.selectedDate,
//         selectedFormattedDate: existingData.selectedFormattedDate,
//         ...(existingData || {}),
//       };      
//       navigate('/CalenderForm', { state: { data: data } });
//     }
//   };

//   /////////////////////////////
//   // alert(selectedCircle);

//   const handleCircleClick = (circle) => {
//   // alert();
//      setSelectedCircle(circle === selectedCircle ? null : circle);
//      handleNextButtonClick(circle === selectedCircle ? null : circle);
//   };

//   const handleCircleClick2 = (circle) => {
//     // alert(circle);
//        setSelectedCircle(circle === selectedCircle ? null : circle);
//     };
  

//     const getCircleStyle = (circle) => ({
//       width: '150px',
//       height: '150px',
//       borderRadius: '50%',
//       border: '3px solid #FC4243',
//       display: 'flex',
//       alignItems: 'center',
//       justifyContent: 'center',
//       cursor: 'pointer',
//       backgroundColor: circle === selectedCircle ? '#FC4243' : 'transparent',
//       color: circle === selectedCircle ? '#ffff' : '#000',
//       // Media query for smaller screens
//       '@media (max-width: 608px)': {
//         width: '100px !important',
//         height: '100px !important',
//         border: '2px solid #FC4243 !important',
//       },
//     });
    
//   const getCircleStyleSmall = (circle) => ({
//     width: '100px',
//     height: '100px',
//     borderRadius: '50%',
//     border: '3px solid #FC4243',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//     cursor: 'pointer',
//     backgroundColor: circle === selectedCircle ? '#FC4243' : 'transparent',
//     color: circle === selectedCircle ? '#ffff' : '#000',
//   });


//   return (
//     <div>
//        <Header />
//        <ToastContainer />
//        {/* <div className='container mt-5'>
//         <h6 style={{color:'#FC4243'}}>HEADING</h6>
//         <h3 style={{fontFamily:'sans-serif'}}>We will reach out to you in just four simple step</h3>
//        </div> */}
       
//        <div className='container my-3' style={{padding: '0'}}>
//         <div className='formBoxsh'>
//           <div className='container'>
//             <Row>
//               <Col xs={5} style={{padding:'0px'}} className='FormImages'>
//               <img src={FormImage} alt='Image' style={{width:'100%',height: '460px',borderRadius:'10px 0px 0px 10px'}} className='FormImages'/>
//               </Col>
//               <Col xs={12} md={7} style={{padding:'0px',position:'relative'}}>
//               <div style={{width:'55%',height: '10%',borderRadius:'0px 10px 10px 0px', border:'1px solid #FC4243', marginTop:'10px', backgroundColor:'#FC4243', display: 'flex',justifyContent: 'center',alignItems: 'center',fontWeight: '600'}}>
//                <Zoom right> <h5 style={{color:'#ffff',fontFamily:'Lato, sans-serif',margin:'0px',fontWeight: '600'}}>Select the property type</h5></Zoom>
//                </div>
//                <button class="button-70" onClick={handlePreButtonClick} style={{position: 'absolute',right: '5%',top: '1%'}}><FaArrowLeftLong className='me-2'/>Back</button>

//                 <div style={{ display: 'flex', justifyContent: 'center', marginTop:'90px' }}>
//                 <Fade right>
//                   <Row>
//                     <Col xs={4} className='text-center py-3 selectPropBox' style={{boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'}}>
//                     <div className='propertyCircle propertyCircle2' style={getCircleStyle('HOUSE')} onClick={() => handleCircleClick('HOUSE')}>
//                         <FaHome size={90} className='propertyCircleIcon'/>
//                     </div>
//                     <h5 className='mt-3'>HOUSE</h5>
//                     </Col>
//                     <Col xs={4} className='text-center py-3 selectPropBox' style={{boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'}}>
//                     <div className='propertyCircle propertyCircle2' style={getCircleStyle('APARTMENT')} onClick={() => handleCircleClick('APARTMENT')}>
//                         <MdApartment size={90} className='propertyCircleIcon' />
//                         {/* <LocationCityIcon style={{fontSize: '100px'}} className='propertyCircleIcon' /> */}
//                         </div>
//                         <h5 className='mt-3'>APARTMENT</h5>
//                     </Col>
//                     <Col xs={4} className='text-center py-3 selectPropBox' style={{boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'}}>
//                     <div className='propertyCircle propertyCircle3' style={getCircleStyle('COMMERCIAL')} onClick={() => handleCircleClick('COMMERCIAL')}>
//                       {/* <BsFillBuildingsFill size={90} className='propertyCircleIcon'/> */}
//                       <StoreIcon style={{fontSize: '100px'}} className='propertyCircleIcon'/>
//                     </div>
//                     <h5 className='mt-3'>COMMERCIAL</h5>
//                     </Col>
//                   </Row>
//                   </Fade>
//                 </div>
//               </Col>
//             </Row>
//           </div>
//         </div>
//  </div>
//         {/* Mobile */}
//         {/* <div className='CalenderMobileView' elevation={4} style={{borderRadius:'10px'}}>
//           <div style={{position: 'relative'}}>
//           <div style={{width:'55%',height: '45px',borderRadius:'0px 10px 10px 0px', border:'1px solid #FC4243', marginTop:'10px', backgroundColor:'#FC4243', display: 'flex',justifyContent: 'center',alignItems: 'center',fontWeight: '600'}}>
//                <Zoom right> <h5 style={{color:'#ffff',fontFamily:'Lato, sans-serif',margin:'0px',fontWeight: '600'}}>Select the property type</h5></Zoom>
//                </div>
//                <button class="button-70" onClick={handlePreButtonClick} style={{position: 'absolute',right: '5%',top: '1%'}}><FaArrowLeftLong className='me-2'/>Back</button>
//                 <div className='container' style={{ display: 'flex', justifyContent: 'center', marginTop:'190px' }}>
//                 <Fade right>
//                   <Row>
//                     <Col xs={4}  className='text-center py-3' style={{boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'}}>
//                     <div className='getCircleStyleSmall' style={getCircleStyleSmall('HOUSE')} onClick={() => handleCircleClick('HOUSE')}>
//                         <FaHome size={70} className='propertyCircleIcon'/>
//                     </div>
//                     <h6 className='mt-3'>HOUSE</h6>
//                     </Col>
//                     <Col xs={4} className='text-center py-3' style={{boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'}}>
//                     <div className='getCircleStyleSmall' style={getCircleStyleSmall('APARTMENT')} onClick={() => handleCircleClick('APARTMENT')}>
//                         <MdApartment size={70} className='propertyCircleIcon' />
//                         </div>
//                         <h6 className='mt-3'>APARTMENT</h6>
//                     </Col>
//                     <Col xs={4} className='text-center py-3' style={{boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'}}>
//                     <div className='getCircleStyleSmall' style={getCircleStyleSmall('COMMERCIAL')} onClick={() => handleCircleClick('COMMERCIAL')}>
//                       <StoreIcon style={{fontSize: '70px'}} className='propertyCircleIcon'/>
//                     </div>
//                     <h6 className='mt-3'>COMMERCIAL</h6>
//                     </Col>
//                   </Row>
//                   </Fade>
//                 </div>
//           </div>
//         </div>
//        */}
//     </div>
//   )
// }


// export default PropertySelect