import React, { useEffect, useRef } from 'react';
import truckHorn from '../images/truckSound.mp3';

const Test2 = () => {
  const audioRef = useRef(new Audio(truckHorn));
  const buttonRef = useRef(null);

  useEffect(() => {
    const handleUserInteraction = () => {
      buttonRef.current.click();
      window.removeEventListener('click', handleUserInteraction); // Remove listener after the first interaction
    };

    // Add event listener for the first user interaction on the page
    window.addEventListener('click', handleUserInteraction);

    return () => {
      window.removeEventListener('click', handleUserInteraction); // Cleanup listener on unmount
    };
  }, []);

  const playSound = () => {
    const audio = audioRef.current;
    audio.play();

    // Stop the audio after 1 second
    setTimeout(() => {
      audio.pause();
      audio.currentTime = 0;
    }, 1000);
  };

  return (
    <div>
      <h1>Did you hear the sound?</h1>
      {/* Hidden button to trigger sound */}
      <button ref={buttonRef} onClick={playSound} style={{ display: 'none' }}>Play Sound</button>
    </div>
  );
};

export default Test2;
