import React, { useState, useEffect } from 'react';
import Header from '../components/Header2'
import {Row,Col} from 'react-bootstrap';
import {useLocation, useNavigate} from 'react-router-dom'
import FormImage from '../images/delivery1.jpg';
import {Fade,Zoom} from 'react-reveal';
import { ToastContainer, toast } from 'react-toastify';
import { FaArrowLeftLong } from "react-icons/fa6";
import GifForm from '../images/FormGif.gif'

const Propertysize = () => {
  const location = useLocation();
  const navigate = useNavigate(); 
  const existingData = location.state && location.state.data;
  const [selectedCircle, setSelectedCircle] = useState(null);
  const [predata, setPredata] = useState(null);
  const [showGif, setShowGif] = useState(false);
  
  useEffect(() => {
    const existingData = location.state && location.state.data;
    console.log('existingDataexistingData', existingData);
    if (existingData !== predata && predata !== null) {
      setPredata(existingData);
    } else if (predata == null) {
      setPredata(existingData);
    }
  }, [location.state, predata]);

  useEffect(() => {
    if (predata && predata.propertySize !== null && predata.propertySize !== undefined) {
      handleCircleClick2(predata.propertySize);
    }
  }, [predata]);

  if (!predata || !predata.from || !predata.to || !predata.selectedDate || !predata.property) {}

  const handleNextButtonClick = (selectedCircle2) => {
    if (selectedCircle2) {
      setShowGif(true); 
      setTimeout(() => {
        setShowGif(false); 
        const existingData = location.state && location.state.data;
        if (!existingData || !existingData.from || !existingData.to || !existingData.selectedDate) {
          navigate('/');
          return;
        }
        const data = {
          from: existingData.from,
          to: existingData.to,
          selectedDate: existingData.selectedDate,
          selectedFormattedDate: existingData.selectedFormattedDate,
          property: existingData.property,
          ...(existingData || {}),
          propertySize: selectedCircle2,
        };
        console.log('NEXT button clicked. Navigating with data:', data);
        navigate('/FinalForm', { state: { data: data } });
      }, 3000);
    } else {
      toast.warn('Please select a Property Size', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
    }
  };

  const handleCircleClick = (circle) => {
    setSelectedCircle(circle === selectedCircle ? null : circle);
    handleNextButtonClick(circle);
  };

  const handleCircleClick2 = (circle) => {
    setSelectedCircle(circle === selectedCircle ? null : circle);
  };

////////CSSSSSS//////////
  const getCircleStyle = (circle) => ({
    width: '150px',
    height: '70px',
    borderRadius: '10%',
    border: '2px solid #0072BC',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    backgroundColor: circle === selectedCircle ? '#0072BC' : '#EEEEEE',
    color: circle === selectedCircle ? '#ffff' : '#000',
  });
  const handlePreButtonClick = () => {
    if (selectedCircle) {
      const existingData = location.state && location.state.data;
      const data = {
        from: existingData.from,
        to: existingData.to,
        selectedDate: existingData.selectedDate,
        selectedFormattedDate: existingData.selectedFormattedDate,
        property: existingData.property,
        ...(existingData || {}), // Spread existing data
        propertySize: selectedCircle,

      };
  
       console.log('NEXT button clicked. Navigating with data:', );

      navigate('/PropertySelect', { state: { data: data } });
    } else {
      const existingData = location.state && location.state.data;

     
      const data = {
        from: existingData.from,
        to: existingData.to,
        selectedDate: existingData.selectedDate,
        property: existingData.property,
        ...(existingData || {}), // Spread existing data
      };     
       navigate('/PropertySelect', { state: { data: data } });
    }
  };

  return (
    <div>
       <Header />
       <ToastContainer />
       <div className='container my-3'>
        <div className='formBoxsh'>
          <div>
            <Row>
              <Col xs={12} md={5} style={{padding:'0px'}} className='FormImages'>
                <img src={FormImage} alt='Imagejs' style={{width:'100%',height: '460px',borderRadius:'10px 0px 0px 10px'}}  />
              </Col>
              <Col xs={12} md={7} style={{padding:'0px',position:'relative'}}>
              <div style={{width:'55%',height: '10%',borderRadius:'0px 10px 10px 0px', border:'1px solid #0C2498', marginTop:'10px', backgroundColor:'#0072BC', display: 'flex',justifyContent: 'center',alignItems: 'center',fontWeight: '600'}}>
               <Zoom right> <h5 style={{color:'#ffff',fontFamily:'Lato, sans-serif',margin:'0px',fontWeight: '600'}}>Select the property size</h5></Zoom>
               </div>
               <button class="button-70" onClick={handlePreButtonClick} style={{position: 'absolute',right: '5%',top: '1%'}}><FaArrowLeftLong className='me-2'/>Back</button>
              <Fade right>
                <div className='container'  style={{marginTop:'100px'}}>
                {!showGif && (
                  <Row>
                    <Col md={4} xs={6} style={{textAlign: '-webkit-center',margin: '20px 0'}}>
                      <div className='propertyCircle' style={getCircleStyle('STUDIO')} onClick={() => handleCircleClick('STUDIO')}>
                       <h6>STUDIO</h6>
                      </div>
                    </Col>
                    <Col md={4} xs={6} style={{textAlign: '-webkit-center',margin: '20px 0'}}>
                      <div className='propertyCircle' style={getCircleStyle('1ROOM')} onClick={() => handleCircleClick('1ROOM')}>
                       <h6>1 ROOM</h6>
                      </div>
                    </Col>
                    <Col md={4} xs={6} style={{textAlign: '-webkit-center',margin: '20px 0'}}>
                      <div className='propertyCircle' style={getCircleStyle('2ROOM')} onClick={() => handleCircleClick('2ROOM')}>
                       <h6>2 ROOM</h6>
                      </div>
                    </Col>
                    <Col md={4} xs={6} style={{textAlign: '-webkit-center',margin: '20px 0'}}>
                      <div className='propertyCircle' style={getCircleStyle('3ROOM')} onClick={() => handleCircleClick('3ROOM')}>
                       <h6>3 ROOMS</h6>
                      </div>
                    </Col>
                    <Col md={4} xs={6} style={{textAlign: '-webkit-center',margin: '20px 0'}}>
                      <div className='propertyCircle' style={getCircleStyle('4ROOM')} onClick={() => handleCircleClick('4ROOM')}>
                       <h6>4 ROOM</h6>
                      </div>
                    </Col>
                    <Col md={4} xs={6} style={{textAlign: '-webkit-center',margin: '20px 0'}}>
                      <div className='propertyCircle' style={getCircleStyle('5ROOM')} onClick={() => handleCircleClick('5ROOM')}>
                       <h6>5+ ROOM</h6>
                      </div>
                    </Col>
                  </Row>
                  )}
             {showGif && (
                <div style={{ position: 'fixed', top: '100px', left: '40%', transform: 'translate(-50%, -50%)' }}>
                  <img src={GifForm} alt='Searching' style={{ width: '130%' }} />
                </div>
              )}
                </div>
                </Fade>
            
              </Col>
            </Row>
          </div>
        </div>

       </div>
 
    </div>
  )
}

export default Propertysize









// import React, { useState, useEffect } from 'react';
// import Header from '../components/Header'
// import {Row,Col,Button} from 'react-bootstrap';
// import {useLocation, useNavigate} from 'react-router-dom'
// import FormImage from '../images/delivery1.jpg';
// import {Fade,Zoom} from 'react-reveal';
// import { ToastContainer, toast } from 'react-toastify';
// import { FaArrowLeftLong } from "react-icons/fa6";

// const Propertysize = () => {
//   const location = useLocation();
//   const navigate = useNavigate(); // Use the useNavigate hook
//   const existingData = location.state && location.state.data;
//   const [selectedCircle, setSelectedCircle] = useState(null);
//   const [predata, setPredata] = useState(null);
  
//   useEffect(() => {
//     const existingData = location.state && location.state.data;
//     console.log("existingDataexistingData",existingData)
//     // Check if existingData is different from predata before updating the state
//     if (existingData !== predata && predata !== null) {
//       setPredata(existingData);
//     } else if(predata == null) {
//       setPredata(existingData);
//     }
//   }, [location.state, predata]); 
  
//   // React.useEffect(() => {
//   //   const existingData = location.state && location.state.data;

//   //   if (!existingData || !existingData.from || !existingData.to || !existingData.selectedDate ||  !existingData.property) {
//   //     // Navigate to a different route if from, to, selectedDate, or property is not present
//   //     navigate('/');
//   //   }
//   // }, [location.state]); // Ensure the effect runs when location.state changes
//   useEffect(() => {
//       if (predata && predata.propertySize !== null && predata.propertySize !== undefined) {
//         handleCircleClick2(predata.propertySize)
//        }
//    }, [predata]); 
//   if (!predata || !predata.from || !predata.to || !predata.selectedDate || !predata.property) {
//   }

//   ///////// end locaton//////////////////////////////////////////////////////

//   const data = location.state && location.state.data;

//   console.log('Data from state:', data);



//   const handleNextButtonClick = (selectedCircle2) => {
//     //event.preventDefault();     
//     if (selectedCircle2) {
//       const existingData = location.state && location.state.data;

//       if (!existingData || !existingData.from || !existingData.to  || !existingData.selectedDate) {
//         // Navigate to a different route if from or to is not present
//         navigate('/');
//         return;
//       }
//       const data = {
//         from: existingData.from,
//         to: existingData.to,
//         selectedDate: existingData.selectedDate,
//         selectedFormattedDate: existingData.selectedFormattedDate,
//         property: existingData.property,
//         ...(existingData || {}), // Spread existing data
//         propertySize: selectedCircle2,
//       };
  
//        console.log('NEXT button clicked. Navigating with data:', );

//       navigate('/FinalForm', { state: { data: data } });
//     } else {
//       // Handle the case where no date is selected
//       // alert('Please select a Property Size.');
//       toast.warn('Please select a Property Size', {
//         position: "top-right",
//         autoClose: 2000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: undefined,
//         theme: "dark",
//         })
//     }
//   };

//   const handlePreButtonClick = () => {
//     if (selectedCircle) {
//       const existingData = location.state && location.state.data;
//       const data = {
//         from: existingData.from,
//         to: existingData.to,
//         selectedDate: existingData.selectedDate,
//         selectedFormattedDate: existingData.selectedFormattedDate,
//         property: existingData.property,
//         ...(existingData || {}), // Spread existing data
//         propertySize: selectedCircle,

//       };
  
//        console.log('NEXT button clicked. Navigating with data:', );

//       navigate('/PropertySelect', { state: { data: data } });
//     } else {
//       const existingData = location.state && location.state.data;

     
//       const data = {
//         from: existingData.from,
//         to: existingData.to,
//         selectedDate: existingData.selectedDate,
//         property: existingData.property,
//         ...(existingData || {}), // Spread existing data
//       };     
//        navigate('/PropertySelect', { state: { data: data } });
//     }
//   };
//   const handleCircleClick = (circle) => {
//     setSelectedCircle(circle === selectedCircle ? null : circle);
//     handleNextButtonClick(circle);
//   };

//   const handleCircleClick2 = (circle) => {
//     setSelectedCircle(circle === selectedCircle ? null : circle);
//   };

//   const getCircleStyle = (circle) => ({
//     width: '120px',
//     height: '70px',
//     borderRadius: '20%',
//     border: '2px solid #FC4243',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//     cursor: 'pointer',
//     backgroundColor: circle === selectedCircle ? '#FC4243' : '#EEEEEE',
//     color: circle === selectedCircle ? '#ffff' : '#000',
//   });

//   return (
//     <div>
//        <Header />
//        <ToastContainer />
//        <div className='container my-3'>
//         <div className='formBoxsh'>
//           <div>
//             <Row>
//               <Col xs={12} md={5} style={{padding:'0px'}} className='FormImages'>
//                 <img src={FormImage} alt='Image' style={{width:'100%',height: '460px',borderRadius:'10px 0px 0px 10px'}}  />
//               </Col>
//               <Col xs={12} md={7} style={{padding:'0px',position:'relative'}}>
//               <div style={{width:'55%',height: '10%',borderRadius:'0px 10px 10px 0px', border:'1px solid #FC4243', marginTop:'10px', backgroundColor:'#FC4243', display: 'flex',justifyContent: 'center',alignItems: 'center',fontWeight: '600'}}>
//                <Zoom right> <h5 style={{color:'#ffff',fontFamily:'Lato, sans-serif',margin:'0px',fontWeight: '600'}}>Select the property size</h5></Zoom>
//                </div>
//                <button class="button-70" onClick={handlePreButtonClick} style={{position: 'absolute',right: '5%',top: '1%'}}><FaArrowLeftLong className='me-2'/>Back</button>
//               <Fade right>
//                 <div className='container'  style={{marginTop:'100px'}}>
//                   <Row>
//                     <Col md={4} xs={6} style={{textAlign: '-webkit-center',margin: '20px 0'}}>
//                       <div className='propertyCircle' style={getCircleStyle('STUDIO')} onClick={() => handleCircleClick('STUDIO')}>
//                        <h6>STUDIO</h6>
//                       </div>
//                     </Col>
//                     <Col md={4} xs={6} style={{textAlign: '-webkit-center',margin: '20px 0'}}>
//                       <div className='propertyCircle' style={getCircleStyle('1ROOM')} onClick={() => handleCircleClick('1ROOM')}>
//                        <h6>1 ROOM</h6>
//                       </div>
//                     </Col>
//                     <Col md={4} xs={6} style={{textAlign: '-webkit-center',margin: '20px 0'}}>
//                       <div className='propertyCircle' style={getCircleStyle('2ROOM')} onClick={() => handleCircleClick('2ROOM')}>
//                        <h6>2 ROOM</h6>
//                       </div>
//                     </Col>
//                     <Col md={4} xs={6} style={{textAlign: '-webkit-center',margin: '20px 0'}}>
//                       <div className='propertyCircle' style={getCircleStyle('3ROOM')} onClick={() => handleCircleClick('3ROOM')}>
//                        <h6>3 ROOMS</h6>
//                       </div>
//                     </Col>
//                     <Col md={4} xs={6} style={{textAlign: '-webkit-center',margin: '20px 0'}}>
//                       <div className='propertyCircle' style={getCircleStyle('4ROOM')} onClick={() => handleCircleClick('4ROOM')}>
//                        <h6>4 ROOM</h6>
//                       </div>
//                     </Col>
//                     <Col md={4} xs={6} style={{textAlign: '-webkit-center',margin: '20px 0'}}>
//                       <div className='propertyCircle' style={getCircleStyle('5ROOM')} onClick={() => handleCircleClick('5ROOM')}>
//                        <h6>5+ ROOM</h6>
//                       </div>
//                     </Col>
//                   </Row>
//                 </div>
//                 </Fade>
//               </Col>
//             </Row>
//           </div>
//         </div>
//        </div>
//     </div>
//   )
// }

// export default Propertysize
