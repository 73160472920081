import React from 'react'
import {Row,Col} from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import FaqImage from '../images/FaqImage2.png'
import Fade from 'react-reveal/Fade';

const FaqHome = () => {
  return (
    <div>
      <div className='container'>
        {/* <h6 style={{ color: '#FC4243' }}>FAQ</h6> */}
        <h1 style={{fontFamily: 'sans-serif'}}>Frequently Asked Questions</h1>
        <div>
          <Row>
            <Col className='mt-4'>
            <Accordion defaultActiveKey={['0']} alwaysOpen flush>
                <Accordion.Item eventKey="0">
                    <Accordion.Header><h6 style={{fontFamily: 'lato,sans-serif'}}>How much do removal services cost in the UK?</h6></Accordion.Header>
                    <Accordion.Body style={{fontFamily:'Lato, sans-serif'}}>
                    Shipping costs in the UK depend on factors such as package weight, size, and the distance between origin and destination. We offer competitive UK shipping rates with no hidden fees, providing an affordable solution for shipping packages across the country.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header><h6 style={{fontFamily: 'Lato,sans-serif'}}>What box and packing supplies do you provide?
                    </h6></Accordion.Header>
                    <Accordion.Body style={{fontFamily:'Lato, sans-serif'}}>
                    Our range of premium packing supplies includes durable boxes in multiple sizes, strong packing tape, bubble wrap, packing peanuts, and other protective materials, all designed to keep your items safe and secure during shipping.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header><h6 style={{fontFamily: 'Lato, sans-serif'}}>Do you ship and deliver on weekends and holidays?</h6></Accordion.Header>
                    <Accordion.Body style={{fontFamily:'Lato, sans-serif'}}>
                    Yes, we offer delivery services on weekends and holidays to ensure your packages arrive on time, regardless of the day or time.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header><h6 style={{fontFamily: 'Lato, sans-serif'}}>What payment method do you accept? </h6></Accordion.Header>
                    <Accordion.Body style={{fontFamily:'Lato, sans-serif'}}>
                    For your convenience, we accept a variety of payment methods, including online payments via credit card, debit card, PayPal, and direct bank transfers, making the shipping process smooth and efficient.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                    <Accordion.Header><h6 style={{fontFamily: 'Lato, sans-serif'}}>How can i get a quote and schedule a package pickup?</h6></Accordion.Header>
                    <Accordion.Body style={{fontFamily:'Lato, sans-serif'}}>
                    To receive a shipping cost estimate and arrange a package pickup, simply use our easy-to-navigate pricing tool or reach out directly to our customer service team. We'll provide a competitive quote tailored to your package’s specifications. </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                    <Accordion.Header><h6 style={{fontFamily: 'Lato, sans-serif'}}>What is your process for safely transporting my packages? </h6></Accordion.Header>
                    <Accordion.Body style={{fontFamily:'Lato, sans-serif'}}>
                    Our secure transportation process prioritizes careful handling by trained staff who follow best practices for loading and unloading. We use modern trucks equipped with GPS tracking and climate control to ensure your packages are transported safely and arrive in perfect condition.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                    <Accordion.Header><h6 style={{fontFamily: 'Lato, sans-serif'}}>How can I track my package delivery status?</h6></Accordion.Header>
                    <Accordion.Body style={{fontFamily:'Lato, sans-serif'}}>
                    You can easily track the delivery status of your package using our online package tracking system. Simply enter your tracking number to view real-time updates on your shipment's location and estimated delivery time.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                    <Accordion.Header><h6 style={{fontFamily: 'Lato, sans-serif'}}>Do you offer packing and unpacking services?</h6></Accordion.Header>
                    <Accordion.Body style={{fontFamily:'Lato, sans-serif'}}>
                    Yes, we offer comprehensive packing and unpacking services to ensure your items are safely prepared for the move and organized at your new location. Our trained team uses high-quality materials to protect your belongings, saving you time and reducing the stress of moving.
                    </Accordion.Body>
                </Accordion.Item>
                </Accordion>
            </Col>
            <Col className='FaqImage'>
              <img src={FaqImage} alt='Image' style={{width:'100%', marginBottom:'20px'}} /> 
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default FaqHome
