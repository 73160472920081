import React from "react";
import Home from './pages/Home';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import {BrowserRouter as Router,Routes,Route} from 'react-router-dom';
import CalenderForm from './pages/CalenderForm'
import PropertySelect from './pages/PropertySelect'
import Propertysize from './pages/Propertysize'
import FinalForm from './pages/FinalForm'
import ThankPage from './pages/ThankPage'
import Contact from './pages/Contact'
import LocalMovers from './pages/LocalMovers'
import Test from './pages/Test'
import Test2 from './pages/Test2'
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import LongDistanceMovers from './pages/LongDistanceMovers'
import StorageServices from './pages/StorageServices'
import JunkRemoval from './pages/JunkRemoval'
import MovingSupplier from './pages/MovingSupplier';
import Blogs from './pages/Blogs';
import PackagingServices from './pages/PackagingServices'
import Aboutus from './pages/Aboutus'
import ThankPage2 from './pages/ThankPage2'
import MoverEdmonton from '../src/landingPages/MoverEdmonton';
import MoverCalgary from '../src/landingPages/MoverCalgary';
import MoversMontreal from '../src/landingPages/MoversMontreal';
import MoversVancouver from '../src/landingPages/MoversVancouver';
import MoversWinnipeg from '../src/landingPages/MoversWinnipeg';
import MoversHalifax from '../src/landingPages/MoversHalifax';
import MoversMoncton from '../src/landingPages/MoversMoncton';
import MoversOttawa from '../src/landingPages/MoversOttawa';
import MoversToronto from '../src/landingPages/MoversToronto';
import MoversVictoria from '../src/landingPages/MoversVictoria';
import MoversNewfoundland from '../src/landingPages/MoversNewfoundland';
import MoversAlberta from '../src/landingPages/MoversAlberta';
import MoversReddeer from '../src/landingPages/MoversReddeer';
import MoversMedicine from '../src/landingPages/MoversMedicine';
import MoversSaskatchewan from '../src/landingPages/MoversSaskatchewan';
import MoversLloydminster from '../src/landingPages/MoversLloydminster';
import MoversGrandePrairie from '../src/landingPages/MoversGrandePrairie';
import MoversGmover from '../src/landingPages/MoversGmover';
import MoversLethbridge from '../src/landingPages/MoversLethbridge';
import MoversFortMcmurray from '../src/landingPages/MoversFortMcmurray';
import MoversBritish from '../src/landingPages/MoversBritish';
import MoversNunavut from '../src/landingPages/MoversNunavut';
import MoversManitoba from '../src/landingPages/MoversManitoba';
import MoversHamilton from '../src/landingPages/MoversHamilton';
import MoversThunderbay from '../src/landingPages/MoversThunderbay';
import Moverskingston from '../src/landingPages/Moverskingston';
import MoversQuebecCity from '../src/landingPages/MoversQuebecCity';
import MoversGreaterSudbury from '../src/landingPages/MoversGreaterSudbury';
import MoversBrampton from '../src/landingPages/MoversBrampton';
import MoversBrunswick from '../src/landingPages/MoversBrunswick';
import MoversNovaScotia from '../src/landingPages/MoversNovaScotia';
import MoversNorthYork from '../src/landingPages/MoversNorthYork';
import Blog1 from './pages/Blog1'
import Blog2 from './pages/Blog2'
import Blog3 from './pages/Blog3'
import Blog4 from './pages/Blog4'
import Blog5 from './pages/Blog5'
import PrivacyPolicy from './pages/PrivacyPolicy'


function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='/CalenderForm' element={<CalenderForm/>}/>
          <Route path='/PropertySelect' element={<PropertySelect/>}/>
          <Route path='/Propertysize' element={<Propertysize/>}/>
          <Route path='/FinalForm' element={<FinalForm/>}/>
          <Route path='/Thankyou' element={<ThankPage/>}/>
          <Route path='/Contact' element={<Contact/>}/>
          <Route path='/local-movers' element={<LocalMovers/>}/>
          <Route path='/long-distance-movers' element={<LongDistanceMovers/>}/>
          <Route path='/storage-services' element={<StorageServices/>}/>
          <Route path='/junk-removal' element={<JunkRemoval/>}/>
          <Route path='/moving-supplier' element={<MovingSupplier/>}/>
          <Route path='/packaging-services' element={<PackagingServices/>}/>
          <Route path='/Aboutus' element={<Aboutus/>}/>
          <Route path='/thankyou2' element={<ThankPage2/>}/>
          <Route path='/blogs' element={<Blogs/>}/>
          <Route path='/how_to_choose_moving_companies_for_stress-free_Move' element={<Blog1/>}/>
          <Route path='/best_long-distance_moving_companies_in_canada' element={<Blog2/>}/>
          <Route path='/cheap_moving_companies_in_canada' element={<Blog3/>}/>
          <Route path='/top10_moving_companies_in_canada' element={<Blog4/>}/>
          <Route path='/do_moving_company_unbox_boxes_and_arrange_household_items' element={<Blog5/>}/>
          <Route path='/privacyPolicy' element={<PrivacyPolicy/>}/>
          {/* Landing pages */}
          <Route path='/mover-in-london' element={<MoverEdmonton/>}/>
          <Route path='/movers-in-birmingham' element={<MoverCalgary/>}/>
          <Route path='/movers-in-manchester' element={<MoversMontreal/>}/>
          <Route path='/movers-in-glasgow' element={<MoversVancouver/>}/>
          <Route path='/movers-in-liverpool' element={<MoversWinnipeg/>}/>

          <Route path='/movers-in-leeds' element={<MoversHalifax/>}/>
          <Route path='/movers-in-edinburgh' element={<MoversMoncton/>}/>
          <Route path='/movers-in-bristol' element={<MoversOttawa/>}/>
          <Route path='/movers-in-sheffield' element={<MoversToronto/>}/>
          <Route path='/movers-in-newcastle-upon-tyne' element={<MoversVictoria/>}/>
          <Route path='/movers-in-nottingham' element={<MoversNewfoundland/>}/>
          <Route path='/movers-in-leicester' element={<MoversAlberta/>}/>
          <Route path='/movers-in-southampton' element={<MoversReddeer/>}/>

          <Route path='/movers-in-portsmouth' element={<MoversMedicine/>}/>
          <Route path='/movers-in-norwich' element={<MoversSaskatchewan/>}/>
          
          <Route path='/movers-in-oxford' element={<MoversLloydminster/>}/>
          <Route path='/movers-in-cambridge' element={<MoversGrandePrairie/>}/>
          <Route path='/movers-in-coventry' element={<MoversGmover/>}/>
          <Route path='/movers-in-derby' element={<MoversLethbridge/>}/>

          <Route path='/movers-in-swansea' element={<MoversFortMcmurray/>}/>
          <Route path='/movers-in-reading' element={<MoversBritish/>}/>
          <Route path='/movers-in-stoke-on-trent' element={<MoversNunavut/>}/>
          <Route path='/movers-in-cardiff' element={<MoversManitoba/>}/>
          <Route path='/movers-in-exeter' element={<MoversHamilton/>}/>
          <Route path='/movers-in-brighton-and-hove' element={<MoversThunderbay/>}/>
          <Route path='/movers-in-plymouth' element={<Moverskingston/>}/>

          <Route path='/movers-in-aberdeen' element={<MoversQuebecCity/>}/>
          <Route path='/movers-in-york' element={<MoversGreaterSudbury/>}/>
          <Route path='/movers-in-milton-keynes' element={<MoversBrampton/>}/>
          <Route path='/movers-in-hull' element={<MoversBrunswick/>}/>
          <Route path='/movers-in-nova-scotia' element={<MoversNovaScotia/>}/>
          <Route path='/movers-in-north-york' element={<MoversNorthYork/>}/>
          <Route path='/Test' element={<Test/>}/>
          <Route path='/Test2' element={<Test2/>}/>
          {/* <Route path='/:location' element={<LandingPage/>}/> */}
        </Routes>
       </Router>
    </div>
  );
}

export default App;
