import React, { useState, useEffect } from 'react';
import Header from '../components/Header2'
import {Row,Col,Button,Form} from 'react-bootstrap';
import {Link, useLocation, useNavigate} from 'react-router-dom'
import FormImage from '../images/delivery1.jpg';
import {Fade,Zoom} from 'react-reveal';
import TextField from '@mui/material/TextField';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Checkbox from '@mui/material/Checkbox';
import swal from 'sweetalert';
import { FaArrowLeftLong } from "react-icons/fa6";
import { ToastContainer, toast } from 'react-toastify';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';


const FinalForm = () => {
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate(); 
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedTimeOrg, setSelectedTimeOrg] = useState(null);
  const [fragile, setFragile] = useState('');
  const [junkRemoval, setJunkRemoval] = useState('');
  const [storageServices, setStorageServices] = useState('');
  const [packagingServices, setPackagingServices] = useState('');
  const [sendMyQuote, setSendMyQuote] = useState('')
  const [predata, setPredata] = useState(null);
  const [ip, setIp] = useState('');
  const [phoneError, setPhoneError] = useState('');



  useEffect(() => {
    const fetchIP = async () => {
      try {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        setIp(data.ip);
      } catch (error) {
        console.error('Error fetching IP:', error);
      }
    };
    fetchIP();
  }, []);
  

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const utmCampaign = searchParams.get('utm_campaign');
    const utmId = searchParams.get('utm_id');

    if (utmCampaign) {
      sessionStorage.setItem('utm_campaign', utmCampaign);
    }

    if (utmId) {
      sessionStorage.setItem('utm_id', utmId);
    }
  }, []);




  useEffect(() => {
    const existingData = location.state && location.state.data;

    // Check if existingData is different from predata before updating the state
    if (existingData !== predata && predata !== null) {
      setPredata(existingData);
        setFullName(predata.fullName || '');
        setEmail(predata.email || '');
        setPhone(predata.phone || '');
        setFragile(predata.fragile || '');
        setJunkRemoval(predata.junkRemoval || '');
        setStorageServices(predata.storageServices || '');
        setPackagingServices(predata.packagingServices || '');
      } else if(predata == null) {
      setPredata(existingData);
    }
  }, [location.state, predata]);

  useEffect(() => {
    const data = location.state && location.state.data;

    console.log('Data from state:', data);
    if (data !== null) {
      setFullName(data.fullName || '');
      setEmail(data.email || '');
      setPhone(data.phone || '');
   //   setSelectedTimeOrg(data.selectedTimeOrg || null);
      setFragile(data.fragile || '');
      setJunkRemoval(data.junkRemoval || '');
      setStorageServices(data.storageServices || '');
      setPackagingServices(data.packagingServices || '');
    }
  }, [location.state]);

  useEffect(() => {
     
    if (predata && predata.fullName !== null && predata.fullName !== undefined) {
       }
  }, [predata]);
  // Include selectedCircle in the dependency array
if (!predata || !predata.from || !predata.to || !predata.selectedDate || !predata.property || !predata.propertySize) {
   navigate('/');
  // return null; 
  }
  

  const handleNextButtonClick = (event) => {
    // Prevent default form submission behavior
    event.preventDefault();
  
    // Set loading to true when the form is submitted
    setLoading(true);
  
    // Create an array of state variables for logging
    const stateArray = [
      { key: 'fullName', value: fullName },
      { key: 'email', value: email },
      { key: 'phone', value: phone },
      { key: 'fragile', value: fragile },
      { key: 'junkRemoval', value: junkRemoval },
      { key: 'storageServices', value: storageServices },
      { key: 'packagingServices', value: packagingServices },
    ];
  
    const utm_campaign = sessionStorage.getItem('utm_campaign')
     const utm_id = sessionStorage.getItem('utm_id')

    // Check if fullName, email, and phone are filled
    if (fullName && email && phone && !phoneError) {
      // Prepare form data
      const formData = {
        form_id: "12345",
        type: predata.property,
        size: predata.propertySize,
        from_loc: predata.from,
        to_loc: predata.to,
        move_date: predata.selectedFormattedDate,
        name: fullName,
        ip:ip,
        email_id: email,
        mobile: phone,
        fragile: fragile,
        avail_time: selectedTime,
        junk_removal: junkRemoval,
        pack_serv: packagingServices,
        storage_serv: storageServices,
        share_quote:sendMyQuote,
        utm_campaign : utm_campaign || 'Organic',
        utm_id: utm_id || 'Organic',
      };
  
      // Log final form data
      console.log("Final form data:", formData);
  
      // Submit form data
      fetch('https://allukmovers.com/ukmoversbackend/api/clientform/storeall', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData)
      })
      .then(response => response.json())
      .then(data => { 
        // Handle response
        if (data.status === 'success') {
          toast.success('Quote successfully submitted', {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            onClose: () => {
              window.location.href = '/Thankyou';
            }
          });
        } else {
          swal('Error', 'An error occurred. Please try again.', 'error');          
        }
      })
      .catch(error => {
        console.error('Error:', error);
        swal('Error', 'An error occurred while processing your request. Please try again.', 'error');
      })
      .finally(() => {
        setLoading(false);
      });
    } else {
      toast.warn('Oops! You missed entering a field', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark"
      });
      // Set loading to false when the form is submitted
      setLoading(false);
    }
  
    // Check if from, to, and selectedDate are present
    const existingData = location.state && location.state.data;
    if (!existingData || !existingData.from || !existingData.to || !existingData.selectedDate) {
      // Navigate to a different route if from, to, or selectedDate is missing
      navigate('/');
      return;
    }
    console.log('NEXT button clicked. Navigating with data:', );
  };



  

  const handlePreButtonClick = (event) => {
    event.preventDefault();   
    if (fullName) {
    const existingData = location.state && location.state.data;
    const data = {
    from: existingData.from,
    to: existingData.to,
    selectedDate: existingData.selectedDate,
    property: existingData.property,
    propertySize: existingData.propertySize,
    selectedFormattedDate: existingData.selectedFormattedDate,
    ...(existingData || {}),
    fullName: fullName || '',
    email: email || '',
    phone: phone || '',
    fragile: fragile || '',
    junkRemoval: junkRemoval || '',
    storageServices: storageServices || '',
    packagingServices: packagingServices || '',
    sendMyQuote: sendMyQuote || '',
    selectedTime: selectedTime || '',
    selectedTimeOrg: selectedTimeOrg || '',
   };
  
  
       console.log('NEXT button clicked. Navigating with data:', );

      navigate('/Propertysize', { state: { data: data } });
    } else {
      const existingData = location.state && location.state.data;
      const data = {
        from: existingData.from,
        to: existingData.to,
        selectedDate:existingData.selectedDate,
        ...(existingData || {}), // Spread existing data
      };     
       navigate('/Propertysize', { state: { data: data } });
    }
  };


  const handleTimeChange = (newTime) => {
    const selectedTime = new Date(newTime);
    const formattedTime = selectedTime.toLocaleTimeString([], {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    });
    setSelectedTime(formattedTime);
    setSelectedTimeOrg(newTime);
    // {$L: 'en', $u: undefined, $d: Thu Feb 01 2024 01:00:00 GMT+0530 (India Standard Time), $y: 2024, $M: 1
    console.log('Selected Time:', newTime);
  };

  const handleCheckboxChange = (checkboxName, checked) => {
    switch (checkboxName) {
      case 'fragile':
        setFragile(checked ? 'yes' : '');
        break;
      case 'junkRemoval':
        setJunkRemoval(checked ? 'yes' : '');
        break;
      case 'storageServices':
        setStorageServices(checked ? 'yes' : '');
        break;
      case 'sendMyQuote':
        setSendMyQuote(checked ? 'yes' : '');
        break;
      case 'packagingServices':
        setPackagingServices(checked ? 'yes' : '');
        break;
      default:
        break;
    }
  };



  const formatPhoneNumber = (value) => {
    if (value.length === 10) {
      return value.replace(/^(\d{3})(\d{3})(\d{4})$/, '$1-$2-$3');
    } else {
      return value;
    }
  };

  const validatePhoneNumber = (value) => {
    const pattern = /^\+?1?[- ]?\(?([2-9]\d{2})\)?[- ]?([2-9]\d{2})[- ]?(\d{4})$/;
    const allowedAreaCodes = [
      '403', '587', '780', '825', '236', '250', '604', '778', '204', '431', '506', '709', '867',
      '902', '867', '226', '249', '289', '343', '416', '437', '519', '613', '647', '705', '807', '905',
      '782', '902', '418', '438', '450', '514', '579', '581', '819', '873', '306', '639', '867'
    ];
    // Check if the phone number has repeating digits
    const repeatingDigitsPattern = /^(\d)\1{2}-?(\d)\2{2}-?(\d)\3{3}$/;

    const matches = value.match(pattern);
    if (!matches) return false;

    // Extract the area code and check if it's in the allowed list
    const areaCode = matches[1];
    if (!allowedAreaCodes.includes(areaCode)) return false;
    return !repeatingDigitsPattern.test(value);
  };

  const handlePhoneChange = (event) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/\D/g, '').slice(0, 10);
    setPhone(formatPhoneNumber(numericValue));
    if (validatePhoneNumber(numericValue)) {
      setPhoneError('');
    } else {
      setPhoneError('Invalid phone number');
    }
  };
  const theme = createTheme({
    palette: {
      primary: {
        main: '#FC4243', 
      },
      blue: {
        main: '#CACACA',
      },
    },
  });

  return (
    <div>
       <Header />
       <ToastContainer/>
       <div className='container my-3'>
        <div className='formBoxsh' >
          <div>
            <Row>
              <Col style={{padding:'0px'}} xs={12} md={5} className='FormImages'>
                <img src={FormImage} alt='dummyImage' style={{width:'100%',height: '460px',borderRadius:'10px 0px 0px 10px'}}  />
              </Col>
              <Col xs={12} md={7} style={{padding:'0px',position:'relative'}}>
               <div style={{width:'55%',height: '10%',borderRadius:'0px 10px 10px 0px', border:'1px solid #001577', marginTop:'10px', backgroundColor:'#0072BC', display: 'flex',justifyContent: 'center',alignItems: 'center',fontWeight: '600'}}>
                <Zoom right><h5 style={{color:'#ffff',fontFamily:'Lato, sans-serif',margin:'0px',fontWeight: '600'}}>Your quote is only a click away</h5></Zoom>
               </div>
               <button class="button-70" onClick={handlePreButtonClick} style={{position: 'absolute',right: '5%',top: '1%'}}><FaArrowLeftLong className='me-2'/>Back</button>
              <Fade right>
              <ValidatorForm onSubmit={handleNextButtonClick}>
                <div className='mt-5 mx-4'>
                  <Row>
                    <Col xs={12} md={6}>
                      <Form.Group>
                        <TextValidator style={{width:'100%'}} label="Enter Full Name*" type='text' variant="outlined" value={fullName}
                          onChange={(event) => setFullName(event.target.value)}   validators={['required']}
                          errorMessages={['this field is required']}/>
                      </Form.Group>
                    </Col>  
                    <Col xs={12} md={6} className='FormFinalInpu'>
                    <Form.Group>
                        <TextValidator style={{width:'100%'}} label="Enter Email ID*" type='email' variant="outlined" value={email}
                         onChange={(event) => setEmail(event.target.value)} validators={['required', 'isEmail']}
                         errorMessages={['this field is required', 'email is not valid']} />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className='pt-4 FormFinalInpu2'>
                    <Col xs={12} md={6}>
                      <Form.Group className='pt-2'>
                      {/* <Form.Control
                        style={{ width: '100%', height: '56px',borderColor: '#C4C4C4' }}
                        type='text'
                        placeholder='Enter Phone number*'
                        value={phone}
                        onChange={handlePhoneChange}
                        isInvalid={phoneError !== ''}/>
                      <Form.Control.Feedback type="invalid">
                        {phoneError}
                      </Form.Control.Feedback> */}
                        <TextField
                        fullWidth
                        label="Enter Phone number*"
                        placeholder="Enter Phone number*"
                        variant="outlined"
                        value={phone}
                        onChange={handlePhoneChange}
                        error={phoneError !== ''}
                        helperText={phoneError}/>
                    </Form.Group>
                    </Col>  
                    <Col xs={12} md={6}>
                    <Form.Group>

                    <ThemeProvider theme={theme}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={['TimePicker']}>
                            <TimePicker
                               label="Available Time To Call"
                               value={selectedTimeOrg}
                               onChange={handleTimeChange}
                              viewRenderers={{
                                hours: renderTimeViewClock,
                                minutes: renderTimeViewClock,
                                seconds: renderTimeViewClock,
                              }}
                              sx={{
                                width: '100%',
                                '& .MuiPickersClockNumber-selected': {
                                  backgroundColor: theme.palette.primary.main,
                                  color: 'white',
                                },
                                '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                    borderColor: theme.palette.blue.main,
                                  },
                                  '&:hover fieldset': {
                                    borderColor: theme.palette.blue.main,
                                  },
                                  '&.Mui-focused fieldset': {
                                    borderColor: theme.palette.blue.main,
                                  },
                                },
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                  </ThemeProvider>
                    </Form.Group>
                    </Col>
                  </Row>
                   <hr/>
                   <Row>

               {/* <Col md={2}>
               <h6 style={{color:'#FC4243', marginTop:'10px'}}>We Also Provide</h6>
              </Col> */}
              <Col md={9}>
                 <Form.Group className='d-flex'>
                        <Checkbox {...label} style={{color:'#0072BC'}} checked={sendMyQuote === 'yes'}
                     onChange={(e) => handleCheckboxChange('sendMyQuote', e.target.checked)} />
                        <Form.Label className='mt-2'>Send my quote request to our alliance partners as well</Form.Label>
                      </Form.Group>
                   </Col>
                   <h6 style={{color:'#0072BC', marginTop:'5px', marginLeft:'10px'}}>We Also Provide</h6>

                    <Col md={4}>
                        <Form.Group className='d-flex mt-0'>
                        <Checkbox {...label} style={{color:'#0072BC'}} checked={junkRemoval === 'yes'}
                     onChange={(e) => handleCheckboxChange('junkRemoval', e.target.checked)} />
                        <Form.Label className='mt-2'>Junk Removal</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group className='d-flex mt-0'>
                        <Checkbox {...label} style={{color:'#0072BC'}} checked={storageServices === 'yes'}
                         onChange={(e) => handleCheckboxChange('storageServices', e.target.checked)}/>
                        <Form.Label className='mt-2'>Storage Serivces</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group className='d-flex mt-0'>
                        <Checkbox {...label} style={{color:'#0072BC'}}  checked={packagingServices === 'yes'}
                         onChange={(e) => handleCheckboxChange('packagingServices', e.target.checked)} />
                        <Form.Label className='mt-2'>Packaging Serivces</Form.Label>
                      </Form.Group>
                    </Col>
              
                   </Row>
                   
                </div>
                   <div className='text-center mt-2'>
                   <Button
                      // onClick={handleNextButtonClick}
                      type="submit" 
                      className='button-70'
                      style={{backgroundColor:'#FC4243', borderColor:'#FC4243',width:'50%', marginTop:'7px'}}
                      // disabled={loading}
                      disabled={!fullName || !email || !phone || phoneError || loading}>
                      {loading ? (
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      ) : (
                        <>SUBMIT</>
                      )}
                    </Button>
                   </div> 
              </ValidatorForm>
                </Fade>
              </Col>
            </Row>
          </div>
        </div>
       </div>
    </div>
  )
}

export default FinalForm






























// import React, { useState, useEffect } from 'react';
// import Header from '../components/Header'
// import Paper from '@mui/material/Paper';
// import { Row, Col, Button, Form } from 'react-bootstrap';
// import { Link, useLocation, useNavigate } from 'react-router-dom'
// import { MdKeyboardArrowRight } from "react-icons/md";
// import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
// import FormImage from '../images/delivery1.jpg';
// import { Fade, Zoom } from 'react-reveal';
// import TextField from '@mui/material/TextField';
// import { TimePicker } from '@mui/x-date-pickers/TimePicker';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import Checkbox from '@mui/material/Checkbox';
// import swal from 'sweetalert';
// import { FaArrowLeftLong } from "react-icons/fa6";
// import { ToastContainer, toast } from 'react-toastify';
// import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
// import { createTheme, ThemeProvider } from '@mui/material/styles';
// import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';

// const FinalForm = () => {
//   const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
//   const [loading, setLoading] = useState(false);
//   const location = useLocation();
//   const navigate = useNavigate();
//   const [fullName, setFullName] = useState('');
//   const [email, setEmail] = useState('');
//   const [phone, setPhone] = useState('');
//   const [selectedTime, setSelectedTime] = useState(null);
//   const [selectedTimeOrg, setSelectedTimeOrg] = useState(null);
//   const [fragile, setFragile] = useState('');
//   const [junkRemoval, setJunkRemoval] = useState('');
//   const [storageServices, setStorageServices] = useState('');
//   const [packagingServices, setPackagingServices] = useState('');
//   const [sendMyQuote, setSendMyQuote] = useState('')
//   const [predata, setPredata] = useState(null);
//   const [ip, setIp] = useState('');
//   const [phoneError, setPhoneError] = useState('');
//   const [utmCampaign, setUtmCampaign] = useState('');
//   const [utmId, setUtmId] = useState('');

//   useEffect(() => {
//     const fetchIP = async () => {
//       try {
//         const response = await fetch('https://api.ipify.org?format=json');
//         const data = await response.json();
//         setIp(data.ip);
//       } catch (error) {
//         console.error('Error fetching IP:', error);
//       }
//     };

//     fetchIP();
//   }, []);

//   useEffect(() => {
//     const existingData = location.state && location.state.data;

//     if (existingData !== predata && predata !== null) {
//       setPredata(existingData);
//       setFullName(predata.fullName || '');
//       setEmail(predata.email || '');
//       setPhone(predata.phone || '');
//       setFragile(predata.fragile || '');
//       setJunkRemoval(predata.junkRemoval || '');
//       setStorageServices(predata.storageServices || '');
//       setPackagingServices(predata.packagingServices || '');
//     } else if (predata == null) {
//       setPredata(existingData);
//     }
//   }, [location.state, predata]);

//   useEffect(() => {
//     const data = location.state && location.state.data;

//     if (data !== null) {
//       setFullName(data.fullName || '');
//       setEmail(data.email || '');
//       setPhone(data.phone || '');
//       setFragile(data.fragile || '');
//       setJunkRemoval(data.junkRemoval || '');
//       setStorageServices(data.storageServices || '');
//       setPackagingServices(data.packagingServices || '');
//     }
//   }, [location.state]);

//   useEffect(() => {
//     if (predata && predata.fullName !== null && predata.fullName !== undefined) {
//     }
//   }, [predata]);

//   useEffect(() => {
//     const searchParams = new URLSearchParams(location.search);
//     const campaign = searchParams.get('utm_campaign');
//     const id = searchParams.get('utm_id');
//     setUtmCampaign(campaign || '');
//     setUtmId(id || '');
//   }, [location.search]);

//   if (!predata || !predata.from || !predata.to || !predata.selectedDate || !predata.property || !predata.propertySize) {
//     navigate('/');
//   }

//   const handleNextButtonClick = (event) => {
//     event.preventDefault();
//     setLoading(true);

//     if (fullName && email && phone && !phoneError) {
//       const formData = {
//         form_id: "12345",
//         type: predata.property,
//         size: predata.propertySize,
//         from_loc: predata.from,
//         to_loc: predata.to,
//         move_date: predata.selectedFormattedDate,
//         name: fullName,
//         ip: ip,
//         email_id: email,
//         mobile: phone,
//         fragile: fragile,
//         avail_time: selectedTime,
//         junk_removal: junkRemoval,
//         pack_serv: packagingServices,
//         storage_serv: storageServices,
//         share_quote: sendMyQuote,
//         utm_campaign: utmCampaign,
//         utm_id: utmId
//       };

//       fetch('https://allcanadavanlines.com/acvlbackend/api/clientform/storeall', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(formData)
//       })
//         .then(response => response.json())
//         .then(data => {
//           if (data.status === 'success') {
//             toast.success('Quote successfully submitted', {
//               position: "top-right",
//               autoClose: 1000,
//               hideProgressBar: false,
//               closeOnClick: true,
//               pauseOnHover: true,
//               draggable: true,
//               progress: undefined,
//               theme: "dark",
//               onClose: () => {
//                 window.location.href = '/Thankyou';
//               }
//             });
//           } else {
//             swal('Error', 'An error occurred. Please try again.', 'error');
//           }
//         })
//         .catch(error => {
//           console.error('Error:', error);
//           swal('Error', 'An error occurred while processing your request. Please try again.', 'error');
//         })
//         .finally(() => {
//           setLoading(false);
//         });
//     } else {
//       toast.warn('Oops! You missed entering a field', {
//         position: "top-right",
//         autoClose: 2000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: undefined,
//         theme: "dark"
//       });
//       setLoading(false);
//     }

//     const existingData = location.state && location.state.data;
//     if (!existingData || !existingData.from || !existingData.to || !existingData.selectedDate) {
//       navigate('/');
//       return;
//     }
//   };

//   const handlePreButtonClick = (event) => {
//     event.preventDefault();
//     if (fullName) {
//       const existingData = location.state && location.state.data;
//       const data = {
//         from: existingData.from,
//         to: existingData.to,
//         selectedDate: existingData.selectedDate,
//         property: existingData.property,
//         propertySize: existingData.propertySize,
//         selectedFormattedDate: existingData.selectedFormattedDate,
//         ...(existingData || {}),
//         fullName: fullName || '',
//         email: email || '',
//         phone: phone || '',
//         fragile: fragile || '',
//         junkRemoval: junkRemoval || '',
//         storageServices: storageServices || '',
//         packagingServices: packagingServices || '',
//         sendMyQuote: sendMyQuote || '',
//         selectedTime: selectedTime || '',
//         selectedTimeOrg: selectedTimeOrg || '',
//         utm_campaign: utmCampaign || '',
//         utm_id: utmId || '',
//       };

//       navigate('/Propertysize', { state: { data: data } });
//     } else {
//       const existingData = location.state && location.state.data;
//       const data = {
//         from: existingData.from,
//         to: existingData.to,
//         selectedDate: existingData.selectedDate,
//         ...(existingData || {}),
//       };
//       navigate('/Propertysize', { state: { data: data } });
//     }
//   };

//   const handleTimeChange = (newTime) => {
//     const selectedTime = new Date(newTime);
//     const formattedTime = selectedTime.toLocaleTimeString([], {
//       hour: 'numeric',
//       minute: '2-digit',
//       hour12: true
//     });
//     setSelectedTime(formattedTime);
//     setSelectedTimeOrg(newTime);
//     console.log('Selected Time:', newTime);
//   };

//   const handleCheckboxChange = (checkboxName, checked) => {
//     switch (checkboxName) {
//       case 'fragile':
//         setFragile(checked ? '1' : '0');
//         break;
//       case 'junkRemoval':
//         setJunkRemoval(checked ? '1' : '0');
//         break;
//       case 'storageServices':
//         setStorageServices(checked ? '1' : '0');
//         break;
//       case 'packagingServices':
//         setPackagingServices(checked ? '1' : '0');
//         break;
//       case 'sendMyQuote':
//         setSendMyQuote(checked ? '1' : '0');
//         break;
//       default:
//         break;
//     }
//   };

//   const handlePhoneChange = (event) => {
//     const inputPhone = event.target.value;
//     const cleanedPhone = inputPhone.replace(/\D/g, '');
//     const formattedPhone = formatPhoneNumber(cleanedPhone);
//     setPhone(formattedPhone);

//     if (isValidPhoneNumber(cleanedPhone)) {
//       setPhoneError('');
//     } else {
//       setPhoneError('Invalid phone number');
//     }
//   };

//   const formatPhoneNumber = (phoneNumber) => {
//     const cleaned = ('' + phoneNumber).replace(/\D/g, '');
//     const match = cleaned.match(/^(\d{1,3})(\d{3})(\d{3,4})$/);
//     if (match) {
//       return `+${match[1]} ${match[2]} ${match[3]}`;
//     }
//     return phoneNumber;
//   };

//   const isValidPhoneNumber = (phoneNumber) => {
//     const phoneRegex = /^\+?\d{1,3}\s?\d{3}\s?\d{3,4}$/;
//     return phoneRegex.test(phoneNumber);
//   };

//   return (
//     <>
//       <ToastContainer
//         position="top-right"
//         autoClose={2000}
//         hideProgressBar={false}
//         newestOnTop={false}
//         closeOnClick
//         rtl={false}
//         pauseOnFocusLoss
//         draggable
//         pauseOnHover
//         theme="dark"
//       />
//       <Header />
//       <div className="overflow-hidden" style={{ backgroundColor: 'white', minHeight: '100vh' }}>
//         <Row className="align-items-center justify-content-center">
//           <Col md={12}>
//             <Link onClick={handlePreButtonClick} className="backbtn">
//               <FaArrowLeftLong style={{ fontSize: '1.5rem' }} />
//               &nbsp;&nbsp; Back
//             </Link>
//           </Col>
//           <Col md={5} className="px-0 d-none d-md-block">
//             <img src={FormImage} alt="form" className="img-fluid" style={{ height: '80vh' }} />
//           </Col>
//           <Col md={7} className="px-5">
//             <ValidatorForm>
//               <Row>
//                 <Col md={12} className="mb-3">
//                   <TextValidator
//                     fullWidth
//                     variant="outlined"
//                     label="Full Name"
//                     onChange={(e) => setFullName(e.target.value)}
//                     name="fullName"
//                     value={fullName}
//                     validators={['required']}
//                     errorMessages={['This field is required']}
//                   />
//                 </Col>
//                 <Col md={12} className="mb-3">
//                   <TextValidator
//                     fullWidth
//                     variant="outlined"
//                     label="Email"
//                     onChange={(e) => setEmail(e.target.value)}
//                     name="email"
//                     value={email}
//                     validators={['required', 'isEmail']}
//                     errorMessages={['This field is required', 'Email is not valid']}
//                   />
//                 </Col>
//                 <Col md={12} className="mb-3">
//                   <TextField
//                     fullWidth
//                     variant="outlined"
//                     label="Phone Number"
//                     value={phone}
//                     onChange={handlePhoneChange}
//                     error={!!phoneError}
//                     helperText={phoneError}
//                     required
//                   />
//                 </Col>
//                 <Col md={12} className="mb-3">
//                   <LocalizationProvider dateAdapter={AdapterDayjs}>
//                     <DemoContainer components={['TimePicker']}>
//                       <TimePicker
//                         label="Available Time"
//                         value={selectedTimeOrg}
//                         onChange={handleTimeChange}
//                         renderInput={(params) => <TextField {...params} fullWidth variant="outlined" />}
//                       />
//                     </DemoContainer>
//                   </LocalizationProvider>
//                 </Col>
//                 <Col md={12} className="mb-3">
//                   <div>
//                     <Checkbox
//                       {...label}
//                       checked={fragile === '1'}
//                       onChange={(e) => handleCheckboxChange('fragile', e.target.checked)}
//                     />
//                     Fragile Items
//                   </div>
//                   <div>
//                     <Checkbox
//                       {...label}
//                       checked={junkRemoval === '1'}
//                       onChange={(e) => handleCheckboxChange('junkRemoval', e.target.checked)}
//                     />
//                     Junk Removal
//                   </div>
//                   <div>
//                     <Checkbox
//                       {...label}
//                       checked={storageServices === '1'}
//                       onChange={(e) => handleCheckboxChange('storageServices', e.target.checked)}
//                     />
//                     Storage Services
//                   </div>
//                   <div>
//                     <Checkbox
//                       {...label}
//                       checked={packagingServices === '1'}
//                       onChange={(e) => handleCheckboxChange('packagingServices', e.target.checked)}
//                     />
//                     Packaging Services
//                   </div>
//                   <div>
//                     <Checkbox
//                       {...label}
//                       checked={sendMyQuote === '1'}
//                       onChange={(e) => handleCheckboxChange('sendMyQuote', e.target.checked)}
//                     />
//                     Send My Quote
//                   </div>
//                 </Col>
//                 <Col md={12} className="text-center">
//                   <Button
//                     variant="primary"
//                     className="px-5 py-2"
//                     disabled={loading}
//                     onClick={handleNextButtonClick}
//                   >
//                     {loading ? 'Submitting...' : 'Submit'}
//                   </Button>
//                 </Col>
//               </Row>
//             </ValidatorForm>
//           </Col>
//         </Row>
//       </div>
//     </>
//   );
// };

// export default FinalForm;









