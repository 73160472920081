import React from 'react';
import FooterMainBg from '../images/footer-bg.jpg'
import {Row,Col, Button} from 'react-bootstrap'
import { FaFacebook } from "react-icons/fa6";
import { TfiInstagram } from "react-icons/tfi";
import { RiTwitterXLine } from "react-icons/ri";
import { MdKeyboardArrowRight } from "react-icons/md";
import { IoMdMail } from "react-icons/io";
import { FaPhoneAlt } from "react-icons/fa";
import { MdLocationPin } from "react-icons/md";
import ACVllogofooter from '../images/Ukmover.png';
import Build from '../images/build.png'
import {Link} from 'react-router-dom'


const FooterMain = () => {
  return (
    <div>
        
        <div className='FooterTopSet' style={{position:'relative'}}>
          <h2 style={{position: 'absolute',right: '10%',top: '15%'}}>
          <main class="d-flex">
                <section class="animation">
                  <div class="first" style={{color:'#000'}}><div>"Making Your Move Across the UK Seamless and Stress-Free"</div></div>
                  <div class="second" style={{color:'#000'}}><div>"From Your Door to Your Destination – Trust in Every Mile"</div></div>
                  <div class="third" style={{color:'#000'}}><div>"At UK Movers, customers are family"</div></div>
                </section>
              </main>
          </h2>
          <img src={FooterMainBg} style={{width:'100%'}} alt='footerimage' />
          <div style={{backgroundColor:'#FC4243',padding: '30px 0',height: '140px',}}>
          <div className='container-fluid' style={{marginLeft:'20px',top: '75%',zIndex: '999', color:'#ffff',width:'auto'}}>
          <Row>
            <Col xs={8} style={{ alignSelf: 'center'}}>
              <h2 style={{color:'#ffff',fontFamily: 'Lato, sans-serif',fontWeight: '700'}}>Sit back and relax</h2>
              <h4 style={{color:'#ffff',fontFamily: 'Lato, sans-serif'}} className='mt-3'>ACVL has your move covered with care from start to end</h4>
            </Col>
            <Col xs={4} style={{position:'relative',textAlign:'center'}}>
              <div style={{position: 'absolute',top: '-115%',right: '10%'}}>
              <span style={{color:'#ffff', marginBottom:'0px'}} className='mb-0'>CALL US TOLL FREE</span>
              <a style={{textDecoration:'none'}} href="tel:+1-833-372-9071"><h2 style={{color:'#ffff'}} className='mt-1'>1-833-372-9071</h2></a>
              <div style={{textAlign: '-webkit-center'}} >
               <div style={{width:'50px', height:'50px', borderRadius:'50%',border:'1px solid #000',
               justifyContent: 'space-around',alignItems: 'center',display: 'flex', backgroundColor:'#23262B'}}>
                <h5 className='mt-1'>OR</h5>
                </div>
               </div>
               <a href='/' style={{textDecoration:'none'}}><Button style={{width:'222px', height:'55px', marginTop:'5px', backgroundColor:'#D9D8D8', color:'#000'}} className='FooterButtonGetq'>
                <h5 className='mb-0' style={{fontFamily:'sans-serif'}}>GET A QUOTE</h5></Button></a>
              </div>
            </Col>
          </Row>
        </div>
          </div>
        </div>
      <header>
</header>
    {/* <div style={{backgroundColor:'#23262B',overflow: 'hidden',position: 'relative'}} >
    <img src={Build} alt="imagehnxbd" style={{opacity: '0.2', position: 'absolute',left: '0',top: '0',width: '100%',height: 'auto'}}  />
      <div className='container'>
      <Row style={{ position: 'relative' }}>
      <Col xs={12} md={4} className='mt-4' style={{ padding: '0 70px' }}>
        <a href='/mover-in-edmonton' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Mover in Edmonton</h6>
        </a>
        <a href='/movers-in-calgary' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Calgary</h6>
        </a>
        <a href='/movers-in-montreal' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Montreal</h6>
        </a>
        <a href='/movers-in-vancouver' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Vancouver</h6>
        </a>
        <a href='/movers-in-winnipeg' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Winnipeg</h6>
        </a>
        <a href='/movers-in-halifax' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Halifax</h6>
        </a>
        <a href='/movers-in-moncton' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Moncton</h6>
        </a>
        <a href='/movers-in-ottawa' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Ottawa</h6>
        </a>
        <a href='/movers-in-toronto' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Toronto</h6>
        </a>
        <a href='/movers-in-victoria' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Victoria</h6>
        </a>
        <a href='/movers-in-newfoundland-and-labrador' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Newfoundland and Labrador</h6>
        </a>
      </Col>
      <Col xs={12} md={4} className='mt-4' style={{ padding: '0 70px' }}>
        <a href='/movers-in-alberta' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Alberta</h6>
        </a>
        <a href='/movers-in-red-deer' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Red Deer</h6>
        </a>
        <a href='/movers-in-medicine-hat' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Medicine Hat</h6>
        </a>
        <a href='/movers-in-saskatchewan' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Saskatchewan</h6>
        </a>
        <a href='/movers-in-lloydminster' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Lloydminster</h6>
        </a>
        <a href='/movers-in-grande-prairie' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Grande Prairie</h6>
        </a>
        <a href='/movers-in-camrose' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Camrose</h6>
        </a>
        <a href='/movers-in-lethbridge' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Lethbridge</h6>
        </a>
        <a href='/movers-in-fort-mcmurray' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Fort McMurray</h6>
        </a>
        <a href='/movers-in-british-columbia' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in British Columbia</h6>
        </a>
        <a href='/movers-in-nunavut' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Nunavut</h6>
        </a>
      </Col>
      <Col xs={12} md={4} className='mt-4' style={{ padding: '0 70px' }}>
        <a href='/movers-in-manitoba' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Manitoba</h6>
        </a>
        <a href='/movers-in-hamilton' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Hamilton</h6>
        </a>
        <a href='/movers-in-thunder-bay' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Thunder Bay</h6>
        </a>
        <a href='/movers-in-kingston' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Kingston</h6>
        </a>
        <a href='/movers-in-quebec-city' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Québec City</h6>
        </a>
        <a href='/movers-in-greater-sudbury' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Greater Sudbury</h6>
        </a>
        <a href='/movers-in-brampton' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Brampton</h6>
        </a>
        <a href='/movers-in-new-brunswick' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in New Brunswick</h6>
        </a>
        <a href='/movers-in-nova-scotia' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Nova Scotia</h6>
        </a>
        <a href='/movers-in-north-york' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in North York</h6>
        </a>
      </Col>
    </Row>
    
      </div>
    </div> */}
 <div style={{backgroundColor:'#23262B',overflow: 'hidden',position: 'relative'}} >
    <img src={Build} alt="imagehnxbd" style={{opacity: '0.2', position: 'absolute',left: '0',top: '0',width: '100%',height: 'auto'}}  />
      <div className='container'>
      <Row style={{ position: 'relative' }}>
      <Col xs={12} md={4} className='mt-4' style={{ padding: '0 70px' }}>
        <a href='/mover-in-london' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in London</h6>
        </a>
        <a href='/movers-in-birmingham' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Birmingham</h6>
        </a>
        <a href='/movers-in-manchester' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Manchester</h6>
        </a>
        <a href='/movers-in-glasgow' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Glasgow</h6>
        </a>
        <a href='/movers-in-liverpool' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Liverpool</h6>
        </a>
        <a href='/movers-in-leeds' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Leeds</h6>
        </a>
        <a href='/movers-in-edinburgh' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Edinburgh</h6>
        </a>
        <a href='/movers-in-bristol' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Bristol</h6>
        </a>
        <a href='/movers-in-sheffield' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Sheffield</h6>
        </a>
        <a href='/movers-in-newcastle-upon-tyne' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Newcastle upon Tyne</h6>
        </a>
        <a href='/movers-in-nottingham' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Nottingham</h6>
        </a>
      </Col>
      <Col xs={12} md={4} className='mt-4' style={{ padding: '0 70px' }}>
        <a href='/movers-in-leicester' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Leicester</h6>
        </a>
        <a href='/movers-in-southampton' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Southampton</h6>
        </a>
        <a href='/movers-in-portsmouth' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Portsmouth</h6>
        </a>
        <a href='/movers-in-norwich' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Norwich</h6>
        </a>
        <a href='/movers-in-oxford' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Oxford</h6>
        </a>
        <a href='/movers-in-cambridge' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Cambridge</h6>
        </a>
        {/* <a href='/movers-in-camrose' style={{ textDecoration: 'none' }}>Coventry
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Camrose</h6>
        </a> */}
        <a href='/movers-in-coventry' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Coventry</h6>
        </a>
        <a href='/movers-in-derby' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Derby</h6>
        </a>
        <a href='/movers-in-swansea' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Swansea</h6>
        </a>
        <a href='/movers-in-reading' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Reading</h6>
        </a>
      </Col>
      <Col xs={12} md={4} className='mt-4' style={{ padding: '0 70px' }}>
        <a href='/movers-in-stoke-on-trent' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Stoke on Trent</h6>
        </a>
        <a href='/movers-in-cardiff' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Cardiff</h6>
        </a>
        <a href='/movers-in-exeter' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Exeter</h6>
        </a>
        <a href='/movers-in-brighton-and-hove' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Brighton and Hove</h6>
        </a>
        <a href='/movers-in-plymouth' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Plymouth</h6>
        </a>
        <a href='/movers-in-aberdeen' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Aberdeen</h6>
        </a>
        <a href='/movers-in-york' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in York</h6>
        </a>
        <a href='/movers-in-milton-keynes' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Milton Keynes</h6>
        </a>
        <a href='/movers-in-hull' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Hull (Kingston upon Hull)</h6>
        </a>
        {/* <a href='/movers-in-north-york' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in North York</h6>
        </a> */}
      </Col>
    </Row>
    
      </div>
    </div> 

 <footer class="footer">
  <div class="footer__addr">
    <h1 class="footer__logo">
      <img src={ACVllogofooter} style={{width:'50%'}} alt='Logo' />
    </h1>
        
    <h2>
      <a href='https://www.facebook.com/profile.php?id=61556106046609' target="_blank" style={{color:'#1C1E22'}}><FaFacebook style={{cursor:'pointer'}} className='me-3 FooterSolicalMedia' size={25}/></a>
      <a href='https://www.instagram.com/allcanadavanlines/' target="_blank" style={{color:'#1C1E22'}}><TfiInstagram style={{cursor:'pointer'}} className='me-3 FooterSolicalMedia' size={25}/></a>
      <a href='https://twitter.com/Acvl453280' target="_blank" style={{color:'#1C1E22'}}><RiTwitterXLine style={{cursor:'pointer'}} className='me-3 FooterSolicalMedia' size={25}/></a>
    </h2>
    <div>
      <p style={{fontFamily:'Lato, sans-serif'}}>
        ACVL has set the standard for seamless household and commercial relocations across Canada.
        With over 2000 satisfied customers, you can trust us to engineer a hassle-free move tailored to 
        your needs.     
      </p>
    </div>
  </div>
  
  <ul class="footer__nav">
    <li class="nav__item">
      <h1 style={{fontFamily:'Lato, sans-serif'}} class="nav__title">Our Services</h1>

      <ul class="nav__ul">
      <li>
          <a href="/local-movers"><MdKeyboardArrowRight style={{color:'#FC4243'}}/>Local Movers</a>
        </li>

        <li>
          <a href="/long-distance-movers"><MdKeyboardArrowRight style={{color:'#FC4243'}}/>Long Distance Movers</a>
        </li>
            
        <li>
          <a href="/junk-removal"><MdKeyboardArrowRight style={{color:'#FC4243'}}/>Junk Removal</a>
        </li>
        <li>
          <a href="/packaging-services"><MdKeyboardArrowRight style={{color:'#FC4243'}}/>Packing Services</a>
        </li>
        <li>
          <a href="/moving-supplier"><MdKeyboardArrowRight style={{color:'#FC4243'}}/>Moving Supplies</a>
        </li>
        <li>
          <a href="/storage-services"><MdKeyboardArrowRight style={{color:'#FC4243'}}/>Storage Services</a>
        </li>
        {/* <li>
          <a href="#"><MdKeyboardArrowRight style={{color:'#FC4243'}}/>Weight Calculation</a>
        </li> */}
      </ul>
    </li>
    <li class="nav__item">
      <h1 style={{fontFamily:'Lato, sans-serif'}} class="nav__title">About Company</h1>

      <ul class="nav__ul">
        <li>
        <a href="/"><MdKeyboardArrowRight style={{color:'#FC4243'}}/>Our Story</a>
        </li>

        <li>
          <a href="/"><MdKeyboardArrowRight style={{color:'#FC4243'}}/>Process</a>
        </li>
        <li>
          <a href="/blogs"><MdKeyboardArrowRight style={{color:'#FC4243'}}/>Our Blog</a>
        </li>
            
 
      </ul>
    </li>
    <li class="nav__item">
      <h1 style={{fontFamily:'Lato, sans-serif'}} class="nav__title">Contact Us</h1>

      <ul class="nav__ul">
        <li>
          <a href="tel:+1-833-372-9071"><FaPhoneAlt style={{color:'#FC4243'}} className='me-2'/>1-833-372-9071</a>
        </li>

        <li>
          <a href="mailto:sales@allcanadavanlines.com"><IoMdMail style={{color:'#FC4243'}} className='me-2'/>sales@allcanadavanlines.com</a>
        </li>
        <li>
          <a target="_blank" href="https://www.google.com/maps/place/515+Kingston+Rd+W,+Ajax,+ON+L1S+6M1,+Canada/@43.8551518,-79.0511801,17z/data=!3m1!4b1!4m6!3m5!1s0x89d4df97c5bd92fd:0xb3fe654394c8e4a!8m2!3d43.8551518!4d-79.0511801!16s%2Fg%2F11c2drlvm_?entry=ttu"><MdLocationPin style={{color:'#FC4243'}} className='me-2'/>
          515 Kingston Rd W, Ajax,<br/>
          <p style={{paddingLeft:'25px'}}>ON L1S 6M1, Canada</p></a>
        </li>
      </ul>
    </li>
    
  </ul>

</footer>
<div style={{backgroundColor:'#002147'}}>
  <div className='container'>
  <Row className='FooterLast'>
    <Col xs={12} md={9}>
       <p style={{fontWeight: '500',fontFamily: 'sans-serif',color:'#ffff',margin:'10px 0px',cursor:'pointer'}}>Copyright © 2024 All Canada Van Lines Inc. All rights reserved.</p>
    </Col>
    <Col xs={12} md={3} className='py-2' >
      {/* <Link to='#' style={{fontWeight: '500',fontFamily: 'sans-serif',color:'#ffff',margin:'10px 20px 0px 0px',cursor:'pointer'}}>Terms of Service</Link> */}
      <a href='/privacyPolicy' style={{ontWeight: '500',fontFamily: 'sans-serif',color:'#ffff',margin:'10px 0px',cursor:'pointer'}}>Terms and conditions</a>
    </Col>
  </Row>
  </div>
</div>
    </div>
  )
}

export default FooterMain


